@mixin dot-before-heading ($margin-right: 15px, $dot-color: $color-brand-green) {
    &::before {
        position: relative;
        top: -5px;
        display: inline-block;
        width: 15px;
        height: 10px;
        margin-right: $margin-right;
        background-color: $dot-color;
        content: "";
    }
}
