.experience-editor {

    .placeholder {
        border: 1px dashed $color-brand-maroon;
        margin-bottom: 10px;
    }

    .placeholder--content {
        @extend %content-contained;
    }

    .expert-quote-promo__quote {
        &:before,
        &:after {
            content: "";
        }
    }

    .teaser-block-section {
        min-height: 400px;
    }

    .statistics-wrapper,
    .accordion-gallery,
    .section-title {
        display: block;
    }

    .person-focus__content {
        min-width: 540px;
    }
}

#commandRow a {
    border-bottom: 0 !important;
}

.scHilghlightedChrome {
    display: none !important;
}

