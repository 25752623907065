/* H tags */
h1, h2, h3, h4, h5, h6 {
    color: $color-text;
}

h1 {
    font-size: $header-font-h1;
    line-height: $header-font-h1-line;

    @include small-breakpoint {
        font-size: #{$header-font-h1 * 0.8};
        line-height: #{$header-font-h1 * 0.8};
    }
}

h2 {
    font-size: $header-font-h2;
    line-height: $header-font-h2-line;

    @include small-breakpoint {
        font-size: #{$header-font-h2 * 0.9};
        line-height: #{$header-font-h2-line * 0.9};
    }
}

h3 {
    font-size: $header-font-h3;
    line-height: $header-font-h3-line;
}

h4 {
    font-size: $header-font-h4;
    line-height: $header-font-h4-line;
}

h5 {
    font-size: $header-font-h5;
    line-height: $header-font-h5-line;
}

h6 {
    font-size: $header-font-h6;
    line-height: $header-font-h6-line;
}

@media only screen and (max-width:799px) {

    h1{
        font-size: 36px !important;
        line-height: 46px !important;
    }

    h2{
        font-size: 32px !important;
        line-height: 42px !important;
    }
    .rte h2 {
        font-size: 32px !important;
        line-height: 42px !important;
    }
    h3{
        font-size: 28px !important;
        line-height: 38px !important;

    }

    h4{
        font-size: 24px !important;
        line-height: 34px !important;

    }

    h5{
        font-size: 20px !important;
        line-height: 32px !important;
    }

    h6{
        font-size: 16px !important;
        line-height: 26px !important;
    }

}

/* Section Heading */
.heading--section-title {
    @extend %content-contained;
    @include dot-before-heading();
    @include line-after-heading();
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 36px;
    font-family: $font-cdmsmith;
    font-size: $font-size-med-2;
    font-style: italic;
    font-weight: normal;
    line-height: 40px;
    color: $color-text;

    @include small-breakpoint {
        padding-top: 25px;
    }
}

.heading--small {
    font-size: $font-size-small;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.heading--small-subtle {
    @extend .heading--small;
    color: $color-text-subtle;
}
