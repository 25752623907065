.social-share {
	background-color: $color-transparent-white-85;
	padding: 16px;
	position: fixed;
		top: 200px;
		right: 0;
	transition: top 0.25s;
	z-index: 100;

	.social-share__buttons li {
		list-style-type: none;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		border-bottom: none;
		text-decoration: none;
	}

	@include small-breakpoint {
		display: none;
	}
}
