$tablet-landscape-height: 768px;

.related-experts {
    @extend %content-contained;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 40px;

    @include small-breakpoint {
        .heading--section-title,
        .related-experts__button-container {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    @media screen and (max-height: $tablet-landscape-height) {
        max-height: 100vh;
    }
}

.related-experts__button-container {
    text-align: center;
    margin: 30px 0;

    @include small-breakpoint {
        margin: 30px 20px;
    }
}

.related-experts__button-container .btn{
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    outline: 2px solid $color-brand-light-blue-acc;
  }
}

.related-experts-block-section--2 .expert-block__media {
    @include aspect-ratio(4, 3);

    @include small-breakpoint {
        @include aspect-ratio(1, 1);
    }
    }

.related-experts-block-section--3 .expert-block__media {
    @include aspect-ratio(1, 1);
}

.expert-block {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 $teaser-padding;
}

.expert-block__link:hover {
    border-bottom: 0;
}

.expert-block__name {
    margin-top: 20px;
    font-size: $font-size-med;
    line-height: $font-size-med-line;
    font-weight: 700;

    @include small-breakpoint {
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
    }

    @media screen and (max-height: $tablet-landscape-height) {
        margin-top: 12px;
    }
}

.expert-block__title {
    font-family: $font-cdmsmith;
    font-size: $body-cdmsmith-font-size;
    font-style: italic;
    font-weight: 400;

}

.expert-block__media {
    @include aspect-ratio(1, 1);
    display: block;
    width: 100%;
    background-color: $color-gray-02;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-height: $tablet-landscape-height) {
        max-height: calc(100vh - 80px);
        width: auto;
    }
}

.expert-block__media-picture {
    inset: 0;
    position: absolute;

    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }
}

.slick-initialized .slick-slide {
    // so we don't accidentally click to a detail page of the non-active slide
    .expert-block__link {
        pointer-events: none; // didn't seem to work on its own. also using js
        cursor: default;
    }

    // override for current slide
    &.slick-current {
        .expert-block__link {
            pointer-events: auto;
            cursor: pointer;
        }
    }
}
