.contact-us {
    display: flex;

    @include small-breakpoint {
        flex-direction: column-reverse;
    }
}

.contact-us__button-wrapper {
    margin-right: 35px;

    @include small-breakpoint {
        margin-right: 0px;
    }
}

.contact-us__button {
    border-width: 0;
    margin-top: 8px;
    min-width: 205px;
}

a.contact-us__button.btn.btn--blue{
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    outline: 2px solid $color-brand-light-blue-acc;
  }
}

.contact-us__heading {
    color: $color-white;
    font-family: $font-cdmsmith;
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
    font-weight: 700;
    margin-bottom: .5em;
}

@include small-breakpoint {
  font-size: $font-size-med;
  line-height: $font-size-med-line;
}

.contact-us__subheading {
    color: $color-white;
    font-family: $font-cdmsmith;
    font-size: $body-cdmsmith-font-size;
    font-weight: 400;
    line-height: $body-cdmsmith-line;
    margin-bottom: 22px;
}
