$icon-size: 30px;
$icon-spacing: 10px;
$max-icons-allowed: 10;

.social-links {
    @include medium-breakpoint {
        order: 1;
        margin-bottom: 50px;
    }
}

.social-links__heading {
    @extend .heading--small;
    font-weight: 400;
    color: $color-white;

    @include medium-breakpoint {
        font-weight: 700;
        font-size: 17px;
    }
}

.social-links__block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    max-height: 100px;
    align-content: flex-start;
    margin-top: 10px;

    @include medium-breakpoint {
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-top: 20px;
        max-height: none;
    }
}

.social-links__icon {
    width: 30px;
    height: 30px;
    margin: 0 10px 10px 0;

    @include medium-breakpoint {
        width: 45px;
        height: 45px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        @extend %no-link-style;
        position: relative;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    a:hover img,
    a:focus img {
        opacity: 0.6;
    }

    .fa-external-link {
        display: none;
        position: absolute;
        right: -7px;
        bottom: 0;
        color: $color-white;
        font-size: 13px;
    }

    a:hover .fa-external-link,
    a:focus .fa-external-link {
        display: block;
    }
}

