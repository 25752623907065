/* Scoped CSS Variables */
.expert-contact-form-container {
    --expert-primary-color: #0370e5;
    --expert-primary-color-hover: #0056b3;
    --expert-error-color: red;
    --expert-background-overlay: rgba(0, 0, 0, 0.5);
    --expert-form-background: #fff;
    --expert-border-color: #ccc;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--expert-background-overlay);
    z-index: 1000;
    overflow-y: auto;
    font-family: "CDM Smith", HelveticaNeue, Helvetica, Arial, sans-serif !important;
}

/* Add this CSS rule to style the required field asterisk */
.expert-form-group label::after {
    content: " *";
    color: var(--expert-error-color);
}

.expert-form-group label.optional-field::after {
    content: "";
}

/* Overlay for the contact form */
.expert-contact-form-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--expert-background-overlay);
    z-index: 1000;
}

/* Popup for the contact form */
.expert-contact-form-popup {
    position: absolute;
    max-width: 500px;
    padding: 35px;
    background-color: var(--expert-form-background);
    border-radius: 5px;
    z-index: 1001;
    width: 30% !important;
    top: 722.667px;
    left: 452px;
    min-width: 370px;
}

/* Close button for the popup */
.expert-contact-form-popup .expert-close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px !important;
    height: 24px !important;
    border: 2px solid #000 !important;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    border-radius: 19px;
    font-size: 14px;
    line-height: 19px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    background: #000;
    color: #fff;
}

/* Header for the popup */
.expert-contact-form-popup h4 {
    text-align: left;
    padding: 10px 0 10px 0;
}

/* Description text in the form */
.expert-contact-form-container .form-description {
    font-size: 14px !important;
    padding: 5px 0 10px 0;
}

/* Form group styling */
.expert-form-group {
    margin-bottom: 15px;
}

.expert-form-group label {
    display: block;
    margin-bottom: 5px;
}

.expert-form-group input,
.expert-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--expert-border-color);
    border-radius: 3px;
    min-width: 20px !important;
    height: 38px !important;
}

.expert-form-group textarea {
    resize: vertical;
}

/* Submit button styling */
button#expertSubmitBtn {
    display: block;
    padding: 10px 44px;
    background-color: var(--expert-primary-color);
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto !important;
    display: flex !important;
    min-width: 150px;
    min-height: 50px;
    font-weight: 600;
    font-family: "CDM Smith", HelveticaNeue, Helvetica, Arial, sans-serif !important;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    transition: background 0.2s ease 0s;
    vertical-align: top;
}

button#expertSubmitBtn:hover {
    background-color: #fff;
    color: var(--expert-primary-color);
    border: 2px solid var(--expert-primary-color);
}

/* Error message styling */
.expert-error-message {
    color: var(--expert-error-color);
    font-size: 0.875em;
}

/* Loading spinner styling */
.expertContactFormMain #expertSubmitBtn #loadingSpinner {
    padding-inline-start: 22px;
}

/* Accessibility improvements */
button#expertSubmitBtn:focus,
.expert-close-btn:focus {
    outline: 2px solid var(--expert-primary-color);
    outline-offset: 2px;
}

/* give full heigh for Message box */
.expert-form-group #expertMessage {
    height: auto !important;
}

/* Responsive design */
@media (max-width: 600px) {
    .expert-contact-form-popup {
        margin: 10px;
        padding: 10px;
        width: 90% !important;
        min-width: 90%;
    }

    .expert-contact-form-popup h4 {
        font-size: 1.25em;
    }

    .expert-contact-form-container #formDescription {
        font-size: 12px !important;
    }

    button#expertSubmitBtn {
        padding: 10px 44px;
    }
}
