.people-river__slide-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;

    a.btn.btn--white.slider-cta__button{
      color: $color-white;
      background-color: $color-brand-light-blue-acc;
      &:hover {
        color: $color-brand-light-blue-acc;
        background-color: $color-white;
        outline: 2px solid $color-brand-light-blue-acc;
      }
    }

}



.people-river__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.people-river__picture {
    block-size: 100%;
    display: block;
    inline-size: 100%;

    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }
}

.people-river__pane--images .people-river__image {
    &--large,
    &--only-image {
        display: block;
    }

    @include medium-breakpoint {
        &--large {
            display: none;
        }

        &--only-image {
            display: block;
        }
    }
}
