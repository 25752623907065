.search-takeover {
	align-items: center;
    background-color: $color-brand-dark-blue; // fallback without opacity
    background-color: $color-transparent-blue-95;
    color: $color-white;
	display: flex;
	justify-content: center;
	height: 100%;
	position: fixed;
		top: 0;
		left: 0;
	width: 100%;
	z-index: 100;

	&.is-hidden {
		display: none;
	}
}

.search-takeover__form {
	padding: 140px 40px;
	position: relative;
}

.search-takeover__header {
	color: $color-white;
	display: block;
	font-size: $font-size-large;
	font-weight: 700;
	line-height: $font-size-large-line;
	margin-bottom: 38px;
	text-transform: none;
}

.search-takeover__searchbox {
	background-color: $color-transparent-white-50;
	border: none;
	color: $color-white;
	padding-right: 41px;
	line-height: 1.4;
	width: 670px;
	appearance: none;

	&:focus {
		border: 1px dotted $color-white;
	}

	&::placeholder {
		color: $color-white;
		font-size: $font-size-small-2;
		font-weight: 300;
		font-style: italic;
		opacity: 1;
	}
}

.search-takeover__search-button {
	background-color: transparent;
	border: none;
	color: $color-white;
	font-size: 20px;
	height: 41px;
	line-height: 41px;
	padding: 0;
	position: relative;
		left: -45px;
		top: 3px;
	width: 41px;

	&:focus {
		border: 1px dotted $color-white;
	}
}


.search-takeover__close-button {
	@extend %no-button-style;
	border-bottom: none;
	color: $color-white;
	display: inline-block;
	height: 40px;
	position: absolute;
		top: 0;
		right: 0;
	width: 40px;

	&:before {
		content: '×';
		display: block;
		font-size: 40px;
		font-weight: 100;
		height: 40px;
		width: 40px;
	}

	&:hover {
		border-bottom: none;
	}

	&:focus {
	    border: 1px dotted $color-white;
	}
}
