$large-carousel-small-bp: 500px;

.large-carousel-section {
  max-width: 1280px;
  margin: 0 auto;

  &.is-full-width {
    max-width: 1680px;
    width: 100%;
  }

  .is-full-width {
    margin: 0;
    width: 100%;
  }
}

.large-carousel {
  @media (max-width: $large-carousel-small-bp) {
    .slick-arrow {
      width: 32px;
    }
  }
}

.large-carousel__slide {
  position: relative;

  .experience-editor & {
    margin-bottom: 20px;
  }
}

.large-carousel__image {
  @include aspect-ratio(16, 9);

  width: 100%;

  &:before {
    height: 100px;

    @media (max-width: $large-carousel-small-bp) {
      padding-top: 81.25%;
    }
  }

  @media (max-width: $large-carousel-small-bp) {
    display: none;
  }
}

.large-carousel__image-picture {
  inset: 0;
  position: absolute;

  img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }
}

.large-carousel__content--wrapper {
  @extend %content-contained;
  padding-left: 80px;

  @include small-breakpoint {
    max-width: 100%;
    padding-left: 0;
  }
}

.large-carousel__content {
  background-color: $color-transparent-white-90;
  max-width: 500px;
  padding: 50px 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  transition: transform 1s ease;

  .btn {
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    &:hover {
      color: $color-brand-light-blue-acc;
      background-color: $color-white;
      outline: 2px solid $color-brand-light-blue-acc;
    }
  }

  .large-carousel--show & {
    bottom: 16.8%;
    padding: 40px 27px;
    top: auto; /* IE does not support top: initial. top: auto; gets the same effect */
    top: initial;
    transform: none;
  }

  @include large-breakpoint {
    max-width: 575px;
    left: 12.5%;
  }

  @include small-breakpoint {
    padding: 35px 30px;
    width: calc(100% - 120px); // arrows + 10px padding either side
    position: relative;
    margin-top: -360px;
    margin-left: auto;
    margin-right: auto;
    transform: none;

    .large-carousel--show & {
      transform: none;
    }
  }

  @media (max-width: $large-carousel-small-bp) {
    padding: 30px 20px;
    margin-top: -240px;
    width: calc(100% - 90px);
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.large-carousel__copyright {
  background-color: $color-dim-60;
  color: $color-white;
  font-size: $font-size-small;
  padding: 10px;
  position: absolute;
  bottom: 50px;
  right: 50px;

  @include small-breakpoint {
    bottom: auto;
    top: 10px;
    right: 10px;
  }
}

.large-carousel__headline {
  font-size: $font-size-med-3;
  line-height: $font-size-med-3-line;
  margin-bottom: 10px;

  @include medium-breakpoint {
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
  }

  @media (max-width: $large-carousel-small-bp) {
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
  }
}

.large-carousel__summary {
  font-size: $font-size-small-3;
  margin-bottom: 15px;

  @include medium-breakpoint {
    font-size: $font-size-small-3;
    display: block;
  }

  @include small-breakpoint {
    // display: none;
  }

  @media (max-width: 480px) {
    display: none;
  }

  @media (max-width: $large-carousel-small-bp) {
    font-size: $body-cdmsmith-font-size;
  }
}

.large-carousel__tag {
  color: $color-brand-dark-blue;
  font-size: $font-size-small;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}
