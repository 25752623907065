$people-river-height-short: 300px;
$people-river-height-base: $people-river-height-short * 2;
$people-river-height-tall: $people-river-height-short * 3;
$people-river-wide: $large-width;
$people-river-narrow: 700px;

/* Experience Editor */
.experience-editor {
    .people-river {
        min-height: 300px;
        @extend %content-contained; // override .is-full-width styles
    }

    .people-river__slide {
        margin-bottom: 20px;
        background-color: $color-gray-73; // only shows when images haven't been added
        border: 10px solid $color-brand-light-blue;
    }
}

.people-river {
    @include small-breakpoint {
        .slick-track {
            display: flex; // forces slides to be equal height
            min-height: 500px;
        }
    }
}

.people-river__slide {
    position: relative;
    display: block;
    margin: 0 auto;
    width: $max-content-width;
    height: calc(#{$max-content-width} / 2); // want slide to have 2:1 ratio at desktop
}

.people-river__pane {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.people-river__pane--images {
    display: flex;

    .people-river__slide-content {
        display: none;
    }
}

.people-river__pane--content {
    display: flex;

    .people-river__image {
        display: none;
    }
}

.people-river__column--skinny .people-river__element-wrapper--4x3 {

    @include medium-breakpoint {
        height: 100%;
    }

    @include small-breakpoint {
        height: 100%;
    }
}

/* Slick Slider */
.people-river.slick-initialized {
    .slick-slide {
        display: flex; // overriding slick slider's display:block
        margin-right: -1px; // to fix 1px gap between slides when some browsers round down %

        &.slick-current {
            z-index: 10; // to allow color bars to overlap slides before & after
        }
    }
}

// Only show the first slide until slider is loaded
.people-river:not(.is-slider) {

    .people-river__slide:nth-child(n+2) {
        @include visually-hidden;
    }
}
