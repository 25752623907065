// STYLES SHARED BY ALL BANNERS
$banner-bottom-margin: 40px;
$skinny-notch-height: 50px;
$wide-notch-height: 85px;
$banner-custom-breakpoint: #{$large-width} + 50px;

.banner__outer-wrapper {
    position: relative;
    width: 100%;
    background-color: $color-white;
}

.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: $max-page-width;
    margin: 0 auto;
    overflow: hidden;
}

.banner--short {
    height: 500px;

    @include small-breakpoint {
        height: 300px;
    }
}

.banner--tall {
    min-height: 600px;
    height: 80vh;
    max-height: 900px;

    @include medium-breakpoint {
        min-height: 500px;
    }
}

// blue color fade behind header
.banner::before {
    background-image: linear-gradient(to top, transparent 0%, $color-transparent-blue 100%);
    width: 100%;
    height: $header-height;
    z-index: 1;
    position: absolute;
    content: "";
}

.banner__media {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    cursor: default;
}

.banner--has-video {
    .banner__image-wrapper {
        display: none;

        @include small-breakpoint {
            display: block;
        }
    }
}

.banner__image {
    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }
}

.banner__overlay {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background: $color-dim-15;

    .quote-banner & {
        display: none;
    }
}

.banner__vid-wrapper {
    pointer-events: none;

    @include small-breakpoint {
        display: none;
    }
}

.banner__vid {
    width: 100%;
    height: 100%;

    .vjs-control-bar {
        display: none;
    }
}

.banner video,
.banner object {
    @include object-fit(cover);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner__content {
    @extend %content-contained;
    @include text-shadow();
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding-top: $header-height;
    z-index: 1;

    /* fade in on load: */
    animation: fadein 2s;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @media (max-width: #{$banner-custom-breakpoint}) {
        padding-right: 40px;
        padding-left: 40px;
    }

    @include small-breakpoint {
        padding: 20px 20px 0;
        margin-top: $header-height-small;
    }
}

.banner--has-small-notch .banner__content {
    padding-bottom: calc(#{$skinny-notch-height} + #{$banner-bottom-margin} + 20px);

    @include medium-breakpoint {
        // no notch on medium
        padding-bottom: 0;
    }
}

.banner--has-large-notch .banner__content {
    padding-bottom: calc(#{$wide-notch-height} + #{$banner-bottom-margin});

    @include medium-breakpoint {
        // no notch on medium
        padding-bottom: 0;
    }
}

.banner__large-notch {
    position: relative;
    z-index: 1;
    max-width: $max-content-width;
    height: auto;
    min-height: #{$wide-notch-height + $banner-bottom-margin};
    margin-top: -#{$wide-notch-height + $banner-bottom-margin};
    margin-right: auto;
    margin-left: auto;
    background-color: $color-white;

    @include large-breakpoint {
        padding: 40px;

        > * {
            padding-left: 0;
            padding-right: 0;
        }

        > .section:first-of-type,
        > .section:first-of-type .section-title,
        > .split-container:first-of-type .split-container__inner > *:first-of-type {
            padding-top: 0;
        }
    }

    @include medium-breakpoint {
        z-index: 0;
        margin-top: 0;
    }

    /* Experience Editor */
    .scEmptyPlaceholder {
        min-height: #{$wide-notch-height + $banner-bottom-margin};
    }
}

.banner__copyright-container {
    align-self: auto;
    flex-grow: 0;
    width: 100%;
    padding-bottom: 10px;
}

.banner__copyright {
    background-color: $color-dim-60;
    color: $color-gray-15;
    font-size: 11px;
    padding: 5px;

    &--large-img {
        @include large-only(inline-block);

        // don't show img copyright over video
        .banner--has-video & {
            display: none;
        }
    }

    &--medium-img {
        @include medium-only(inline-block);
    }

    &--small-img {
        @include small-only(inline-block);
    }
}
