@mixin line-after-heading ($margin-left: 15px, $border-color: $color-gray-35) {
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-left: $margin-left;
        background-color: $border-color;
        content: "";
    }

    @include small-breakpoint {
        &::after {
            content: none; // removes line
        }
    }
}

