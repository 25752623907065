.quote-banner__content {
    justify-content: center;

    .quote-banner__wrapper {
        align-content: flex-end;
        padding-bottom: 50px;
    }
}

.quote-banner__wrapper {
    align-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
    width: 100%;

    @include small-breakpoint {
        justify-content: center;
    }
}

.quote-banner__quote {
    max-width: 100%;
    font-family: $font-cdmsmith;
    color: $color-white;
    font-style: italic;
    font-size: $font-size-large-2;
    line-height: $font-size-large-2-line;
    text-align: center;

    @include small-breakpoint {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }
}

.quote-banner__author {
    @extend .heading--small-subtle;
    align-self: flex-end;
    width: 100%;
    max-width: 800px;
    margin-top: 35px;
    color: $color-gray-15;
    text-align: center;
    padding: 20px;

    @include small-breakpoint {
        margin-top: 0;
        line-height: 16px;
    }
}
