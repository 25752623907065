.region-takeover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-brand-dark-blue; // fallback without opacity
    background-color: $color-transparent-blue-95;
    z-index: 100;
    overflow: visible;

    &.is-hidden {
        display: none;
    }
}

.region-takeover__centering-helper {
    @extend %flex-center;
    width: 100%;
    height: 100vh;
}

.region-takeover__container {
    @extend %content-contained;
    position: relative;
    height: 100%;
    padding: 30px;

    @include small-breakpoint {
        padding: 0 20px 20px;
    }
}

.region-takeover__close-button {
    @extend %no-button-style;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 10px 5px 5px;
    text-align: right;
    margin-right: 30px;

    @include small-breakpoint {
        padding: 10px;
        margin-right: 0;
    }

    // the X
    &::after {
        content: $x;
        color: $color-white;
        font-family: $font-sans;
        font-size: 50px;
        line-height: 25px; // small to fix large vertical space in font;
        font-weight: normal;

        @include small-breakpoint {
            font-size: 40px;
        }
    }

    &:hover::after {
        color: $color-transparent-white-50;
    }
}

.region-takeover__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 70%;
    height: 100%;

    @include small-breakpoint {
        width: 100%;
        padding-top: 40px; // leave room for the X
    }
}

.region-takeover__title {
    color: $color-white;
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;

    @include small-breakpoint {
        font-size: 26px;
        line-height: 30px;
    }
}

.region-takeover__subtitle {
    color: $color-white;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
}

.region-takeover__list {
    list-style: none;
    margin-top: 30px;
    width: calc(100% + 20px);
    margin-left: -10px;
    overflow-x: hidden;
    overflow-y: auto;

    @include small-breakpoint {
        width: 100%;
        margin-left: 0;
    }
}

.region-takeover__item {
    border-bottom: 1px solid $color-transparent-white-50;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;

    @include small-breakpoint() {
        margin-left: 0;
        margin-right: 0;
    }

    &:first-child {
        border-top: 1px solid $color-transparent-white-50;
    }

    &:hover,
    &:focus {
        background-color: $color-transparent-white-10;
        // styles below so text doesn't butt up against edge of background color
        width: calc(100% + 20px);
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0;
        margin-right: 0;
        border-top: 0;
        border-bottom: 0;

        @include small-breakpoint {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.region-takeover__link {
    @extend %no-link-style;
    display: block;
    width: calc(100% - 20px);
    padding: 15px 0;
    color: $color-white;
    text-transform: uppercase;
    font-size: $font-size-small-2;
    font-weight: 700;
    line-height: $font-size-small-2-line;
    letter-spacing: 1.5px;

    @include small-breakpoint() {
        width: 100%;
    }
}

.region-takeover__item:hover .region-takeover__link {
    border-bottom: 1px solid $color-transparent-white-50;
}

.region-takeover__item:first-child:hover .region-takeover__link {
    border-top: 1px solid $color-transparent-white-50;
}

