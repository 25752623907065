.back-to-top {
    background-color: $color-brand-light-blue;
    border-radius: 50%;
    height: 50px;
    opacity: 0.5;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: $anim-speed-quick;
    width: 50px;
    z-index: 10;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    &.is-hidden {
        display: none;
    }

    @include small-breakpoint {
        height: 30px;
        width: 30px;
        padding: 0;
    }
}

.back-to-top__arrow {
    display: flex;
    width: 100%;
    height: 100%;

    &::before {
        border-style: solid;
        border-width: 7px 7px 0 0;
        color: $color-white;
        content: '';
        display: inline-block;
        height: 13px;
        left: 50%;
        margin-left: -10px;
        margin-top: -7px;
        position: relative;
        top: 50%;
        transform: translateZ(1px) rotate(-45deg);
        vertical-align: top;
        width: 13px;

        @include small-breakpoint {
            border-width: 2px 2px 0 0;
            height: 8px;
            width: 8px;
            margin-left: -5px;
            margin-top: -3px;
        }
    }
}