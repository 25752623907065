.offices-search__map-section {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 50px;
    text-align: center;

    @include small-breakpoint {
        margin-top: 30px;
    }
}

.offices-map {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    border: 1px solid $color-brand-dark-blue;
    z-index: 1;
}

.offices-map__marker {
    background: transparent;
    border: 0;

    .fa {
        color: $color-brand-light-blue;
        text-shadow: 1px 1px 5px $color-dim-30;
    }
}

.office-popup {
    font-size: $font-size-small;
}

.office-popup__name {
    font-weight: 700;
}

.office-popup__label {
    font-weight: 700;
}

.leaflet-control-attribution {
    @include small-breakpoint {
        font-size: 10px;
        text-align: left;
    }
}
