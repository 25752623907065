.logo-link {
    @extend %no-link-style;
    display: inline-block;
    height: 100%;
    max-width: 100%;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.logo--light,
.logo--dark {
    block-size: auto;
    object-fit: cover;
}

.cdmsmith-logo {
    height: 100%;

    @include small-breakpoint {
        height: 100%;
    }
}
