.latest-news__entries {
	@extend %content-contained;
	display: flex;
	flex-wrap: wrap;
}

.latest-news__entry {
	margin-bottom: 60px;
	width: 50%;

	&:nth-child(2n) {
		padding-left: 30px;
	}

	&:nth-child(2n + 1) {
		padding-right: 30px;
	}

	@include small-breakpoint {
		width: 100%;

		&:nth-child(2n) {
			padding-left: 0;
		}

		&:nth-child(2n + 1) {
			padding-right: 0;
		}
	}
}

.latest-news__date {
	@extend .heading--small-subtle;
	color: $color-gray-15;
}

.latest-news__title {
	font-size: $font-size-small-2;

	a {
		border-bottom-width: 0;
	}
}

.latest-news__button-wrapper {
	text-align: center;
}

a.latest-news__button.btn.btn--blue{
	color: $color-white;
	background-color: $color-brand-light-blue-acc;
	&:hover {
		color: $color-brand-light-blue-acc;
		background-color: $color-white;
		outline: 2px solid $color-brand-light-blue-acc;
	}
}
