.copyright {
    display: block;
    font-family: $font-cdmsmith;
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    color: $color-white;
    padding-bottom: 20px;

    @include medium-breakpoint {
        padding-bottom: 0;
        line-height: 20px;
    }
}

.copyright__trademark {
    padding-right: 20px;

    @include medium-breakpoint {
        padding-right: 15px;
    }
}
