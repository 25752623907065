$search-facets-border: $color-gray-15;
$search-facets-background-color: $color-gray-02;
$search-facet-color: $color-gray-87;
$search-facets-xsmall-breakpoint: 550px;

.search-facets {
	@extend %content-contained;
	max-width: 1280px;
	padding: 50px 40px;

	@include small-breakpoint {
		padding: calc(40px / 2);
	}
}

.search-facets__group {
	display: inline-block;
	margin-right: 6%;
	margin-top: 30px;
	position: relative;
	width: 29.33%;
	vertical-align: top;

	&:nth-child(3n) {
		margin-right: 0;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		margin-top: 0;
	}

	.search-facets__group {
		margin-right: 0;
		width: 100%;
	}

	@include medium-breakpoint {
		width: 31.33%;
		margin-right: 3%;
	}

	@include small-breakpoint {
		margin-right: 5%;
		width: 47.5%;

		&:nth-child(3n) {
			margin-right: 5%;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		&:nth-child(3) {
			margin-top: 30px;
		}
	}

	@media(max-width: $search-facets-xsmall-breakpoint) {
		margin-top: 20px;
		margin-right: 0;
		width: 100%;

		&:nth-child(2),
		&:nth-child(3) {
			margin-top: 20px;
		}
	}
}

.search-facets__toggle {
	display: none;
}

.search-facets__group-values {
	.search-facets__group-values {
		list-style: none;
	}
}

.search-facets__group-value {
	list-style: none;
}

.search-facets__title {
	color: $search-facet-color;
	font-size: $body-cdmsmith-font-size;
	font-weight: normal;
	margin-bottom: 0;
	padding: 3px;
	text-transform: none;

	&:hover {
		cursor: pointer;
		background-color: $search-facets-background-color;
	}
}

.search-facet__selected {
	background-color: $search-facets-background-color;
}

.search-facets__group-title {
	color: $color-gray-40;
	font-size: $font-size-small;
	font-weight: 700;
	line-height: $font-size-small-line;
	margin-bottom: 15px;
	text-transform: uppercase;

	.search-facets__group-wrapper & {
		font-size: $body-cdmsmith-font-size;
		color: $search-facet-color;
		text-transform: none;
		margin-bottom: 0;
		padding: 30px 0 3px 10px;
	}
}

.search-facets__group-wrapper {
	display: none;
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.08);
	background-color: $color-white;
	border: 1px solid $search-facets-border;
	border-top: none;
	padding-bottom: 20px;
	padding-top: 20px;

	.search-facets__group-wrapper {
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.highlighted {
		border-bottom: 1px solid $color-brand-green;
	}

	&.search-facets__dropdown-active,
	.search-facets__dropdown-active & {
		display: block;
	}

	&.search-facets__dropdown-active {
		position: absolute;
		width: 100%;
		z-index: 10;
		max-height: 350px;
		overflow-y: auto;
	}
}

.search-facets__input-wrapper {
	display: flex;
}

.search-facets__text-field {
	border-color: $search-facets-border;
	border-right: none;
	height: 40px;
	min-width: 0;
	width: calc(100% - 40px); // 40px width of dropdown arrow
}

.search-facets__no-results {
	background-color: $color-white;
	border: 1px solid $search-facets-border;
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.08);
	color: $color-gray-40;
	font-size: 14px;
	font-style: italic;
	line-height: 14px;
	padding: 15px 10px;
	position: absolute;
	width: 100%;
	z-index: 1;

	&:hover {
		cursor: default;
	}
}

.is-level-0 > .search-facets__group-value > .search-facets__title {
	padding-left: 20px;
}
.is-level-1 > .search-facets__group-value > .search-facets__title {
	padding-left: 40px;
}
.is-level-2 > .search-facets__group-value > .search-facets__title {
	padding-left: 60px;
}

.search-facets__dropdown-toggle {
	align-items: center;
	background-color: $color-white;
	border: 1px solid $search-facets-border;
	display: flex;
	flex-shrink: 0;
	height: 40px;
	justify-content: center;
	vertical-align: bottom;
	width: 40px;

	&:hover {
		cursor: pointer;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid darken($color-brand-green, 5%);
	}
}