.slider-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    position: relative;
    width: 100%;

    @include medium-breakpoint {
        padding: 20px 20px 25px;
    }

    @include small-breakpoint {
        padding: 10px 10px 20px;
    }

}

.slider-cta__heading,
.slider-cta__subheading {
    @include text-shadow($x-position: 0, $y-position:0, $blur-radius:12px, $color: $color-black);
    font-weight: bold;
    color: $color-white;
}

.slider-cta__heading {
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
    max-width: 100%;

    @include medium-breakpoint {
        font-size: $font-size-med-2;
        line-height: $font-size-med-2-line;
    }

    @include small-breakpoint {
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
    }
}

.slider-cta__subheading {
    font-size: $font-size-small-3;
    line-height: $font-size-small-3-line;
    width: 100%;

    @include small-breakpoint {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }
}

.slider-cta__button {
    border: 0;
    box-shadow: none;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    margin-top: 20px;
    max-width: 100%;
    width: auto;

    @include medium-breakpoint {
        min-height: 0;
        margin-top: 15px;
        margin-bottom: 0;
        padding: 15px 20px;
        text-align: center;
    }

    @include small-breakpoint {
        margin-top: 10px;
        padding: 15px 10px;
    }
}

@media only screen and (max-width:799px) {
    .slider-cta__heading{
        font-size: $font-size-small-3 !important;
        line-height: $font-size-small-3-line !important;
    }

    .slider-cta__subheading{
        font-size: $font-size-small-3 !important;
        line-height: $font-size-small-3-line !important;
    }

    .slider-caption__heading, .slider-caption__subheading{
        font-size: $body-cdmsmith-font-size !important;
        line-height: $body-cdmsmith-line !important;
    }
}

a.btn.btn--white.slider-cta__button{
  color: $color-brand-light-blue-acc;
  background-color: $color-white;
  &:hover {
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    outline: 2px solid $color-white;
  }
}

/** Layout Variations **/
.people-river__column--wide,
.people-river__column--full {
    .slider-cta {
        justify-content: flex-end;
        align-items: flex-start;
        max-width: 65%;

        @include medium-breakpoint {
            align-items: center;
            justify-content: flex-end;
            max-width: 100%;
            /*padding-bottom: 50px;*/
            z-index: 1;

            // colored overlay
            &:before {
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: background $anim-speed-slow;
                width: 100%;
                z-index: -1;
            }
        }
    }

    .slider-cta__heading,
    .slider-cta__subheading,
    .slider-cta__button {
        text-align: left;

        @include medium-breakpoint {
            text-align: center;
        }
    }
}

.people-river__column--skinny {
    .slider-cta {
        justify-content: flex-end;
        align-items: center;
        max-width: 100%;
        z-index: 1;

        @include medium-breakpoint {
            /*padding-bottom: 50px;*/
            justify-content: flex-end;
        }
        // colored overlay
        &:before {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: background $anim-speed-slow;
            width: 100%;
            z-index: -1;
        }
    }

    .slider-cta__heading,
    .slider-cta__subheading,
    .slider-cta__button {
        text-align: center;
    }
}

/** Color Themes **/
.slider-cta--theme {
    &-maroon {
        &::before {
            background-color: rgba($color-brand-maroon, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-maroon, 0.8);
        }
    }

    &-orange {
        &::before {
            background-color: rgba($color-brand-orange, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-orange, 0.8);
        }
    }

    &-green {
        &::before {
            background-color: rgba($color-brand-green, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-green, 0.8);
        }
    }

    &-teal {
        &::before {
            background-color: rgba($color-brand-teal, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-teal, 0.8);
        }
    }

    &-light-blue {
        &::before {
            background-color: rgba($color-brand-light-blue, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-light-blue, 0.8);
        }
    }

    &-blue {
        &::before {
            background-color: rgba($color-brand-dark-blue, 0.5);
        }
        &:hover::before {
            background-color: rgba($color-brand-dark-blue, 0.8);
        }
    }
}
