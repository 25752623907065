.slider-caption {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.slider-caption__content {
    width: 100%;
    padding: 15px 25px;
    color: $color-white;
    background: $color-dim-50;

    > * {
        @include medium-breakpoint {
            z-index: 10;
            position: relative;
        }
    }

    @include small-breakpoint {
        padding: 5px 10px;
    }
}

.slider-caption__heading,
.slider-caption__subheading {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
    font-weight: bold;

    @include small-breakpoint {
        font-size: $font-size-small;
        line-height: $font-size-small-line;
    }
}

.slider-caption__subheading {
    margin-top: 5px;
}
