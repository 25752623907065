.slick-slide {
    height: auto;
}

.slick-arrow {
    display: block;
    z-index: 1;
    width: 50px;
    height: 150px;
    color: $color-white;
    background-color: $color-brand-dark-blue !important; // !impt to fix bug where it goes transparent after click
    opacity: 0.8;
    transition: opacity 0.2s ease;
    transform: translate(0,-50%) translateZ(0);

    @include small-breakpoint {
        width: 30px;
        height: 100px;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }

    &:before {
        content: ''; // override content of existing slider
        background-image: url("/assets/build/img/svg-sprite/caret.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center;
        display: block;
        margin: 0 auto;
        opacity: 1;
        width: 100%;
        height: 30px;

        @include retina-screen {
            background-size: 40px;
        }

        @include small-breakpoint {
            /*height: 15px;*/

            @include retina-screen {
                background-size: 10px;
            }
        }
    }
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;

    &:before {
        transform: rotate(-180deg);
    }
}


// if component is full width, override negative offset on arrows
.is-full-width {
    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}