.person-focus__slide {
	@extend %cf;
	position: relative;
	z-index: 0;

	&.slick-current {
		z-index: 1; // hides overflowing content on non-current slides
	}
}

.person-focus__image-container {
	@include aspect-ratio(4,3);

	float: right;
	overflow: hidden;
	width: 90%;

	@include medium-breakpoint {
		width: 100%;
		margin-bottom: -50%;
	}

	@include small-breakpoint {
		@include aspect-ratio(3,4);
	}
}

.person-focus__image-picture {
	inset: 0;
	position: absolute;

	img {
		block-size: 100%;
		inline-size: 100%;
		object-fit: cover;
	}
}

.person-focus__content-container {
	max-width: $max-content-width;
	margin: 0 auto;

	@include medium-breakpoint {
		display: flex;
		position: relative;
		width: 100%;
	}
}

.person-focus__content {
	background-color: $color-transparent-light-blue; // default theme
	margin: 0 auto;
	padding: 30px 20px;
	width: calc(100% - 70px);

	@include large-breakpoint {
		background-color: $color-transparent;
		max-width: 540px;
		padding: 70px 80px 80px 70px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&::before {
			left: calc((100vw - 1200px) / 2 * -1);
			width: calc(100% + ((100vw - 1200px) / 2));
			background-color: $color-transparent-light-blue; // default theme
			content: '';
			display: block;
			position: absolute;
			top: 0;
			height: 100%;
			z-index: -1;
		}
	}

	@include medium-breakpoint {
		width: calc(100% - 150px);
	}

	@include small-breakpoint {
		width: calc(100% - 70px);
	}

	/* color theme options */
	&.theme-blue {
		background-color: $color-transparent-blue;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-blue;
			}
		}
	}
	&.theme-light-blue {
		background-color: $color-transparent-light-blue;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-light-blue;
			}
		}
	}
	&.theme-green {
		background-color: $color-transparent-green;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-green;
			}
		}
	}
	&.theme-orange {
		background-color: $color-transparent-orange;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-orange;
			}
		}
	}
	&.theme-teal {
		background-color: $color-transparent-teal;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-teal;
			}
		}
	}
    &.theme-maroon {
		background-color: $color-transparent-maroon;
		@include large-breakpoint {
			background-color: $color-transparent;
			&::before {
				background-color: $color-transparent-maroon;
			}
		}
	}
}

.person-focus__content > * {
	// all content chunks have same top margin
	margin-top: 40px;

	@include medium-breakpoint {
		margin-top: 20px;
	}

	// except first item, which doesn't need margin top
	&:first-child {
		margin-top: 0;
	}
}

.person-focus__attributes ~ .person-focus__bio {
	// exception to the rule of all text having same top margin
	margin-top: 10px;
}

.person-focus__quote {
	display: block;
	color: $color-white;
	font-family: $font-cdmsmith-light-sans !important;
	font-size: $font-size-med-2;
	line-height: $font-size-med-2-line;
	font-style: normal;
	font-weight: 400;

	@include small-breakpoint {
		font-size: $font-size-small-3;
		line-height: $font-size-small-3-line;
	}
}

.person-focus__attribute {
	color: $color-white;
	font-size: $font-size-small-2;
	line-height: $font-size-small-2-line;
	font-weight: 700;

	@include small-breakpoint {
		font-size: $body-cdmsmith-font-size;
		line-height: $body-cdmsmith-line;
	}
}

.person-focus__bio {
	color: $color-white;
	font-size: $font-size-small-2;
	line-height: $font-size-small-2-line;

	@include small-breakpoint {
		font-size: $body-cdmsmith-font-size;
		line-height: $body-cdmsmith-line;
	}
}

.person-focus__links {
	color: $color-white;
	font-size: $font-size-small-2;

	@include medium-breakpoint {
		font-size: $font-size-small-2;
	}
	@include small-breakpoint {
		font-size: $body-cdmsmith-font-size;
		
	}
}

.person-focus__link {
	border-bottom-color: $color-white;
	color: $color-white;
	font-size: $font-size-small-2;
	line-height: $font-size-small-2-line;
	cursor: pointer;

	@include medium-breakpoint {
		font-size: $body-cdmsmith-font-size;
		line-height: $body-cdmsmith-line;
	}
	@include small-breakpoint {
		font-size: $body-cdmsmith-font-size;
		line-height: $body-cdmsmith-line;
	}

	.fa-external-link {
		color: inherit;
	}
}

a.person-focus__link{
	color: $color-white;
	border-bottom: 2px solid $color-white;
	&:hover {
		color: $color-white;
    border-bottom: 4px solid $color-white;
  }
}

.person-focus__divider {
	background-color: $color-white;
	display: block;
	height: 4px;
	width: 75px;
}
