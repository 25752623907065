.footer-nav {
    @include large-only(inline-block);

    ul {
        list-style: none;
    }

    li {
        @extend .heading--small;
        margin-bottom: 20px;
    }

    li > a {
        font-weight: 400;
        color: $color-white;
    }
}