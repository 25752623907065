$skinny-bar-width: 100px;
$wide-bar-width: 300px;
$right-split-position: 400px;
$left-split-position: 800px;
$edgeBarEndPosition: calc(#{$skinny-bar-width} / -2);
$centerBarEndPositionLeftSkinny: calc( #{$left-split-position} - (#{$skinny-bar-width} / 2) );
$centerBarEndPositionLeftWide: calc( #{$left-split-position} - (#{$wide-bar-width} / 2) );
$centerBarEndPositionRightSkinny: calc( #{$right-split-position} - (#{$skinny-bar-width} / 2) );
$centerBarEndPositionRightWide: calc( #{$right-split-position} - (#{$wide-bar-width} / 2) );

/** Experience Editor **/
.experience-editor {
    // show color bars in final positions
    .slide-layout--orientation-left .people-river__color-bar {
        &--edge {
            right: $edgeBarEndPosition;
        }

        &--center.people-river__color-bar--skinny {
            left: $centerBarEndPositionLeftSkinny;
        }

        &--center.people-river__color-bar--wide {
            left: $centerBarEndPositionLeftWide;
        }
    }

    .slide-layout--orientation-right .people-river__color-bar {
        &--edge {
            left: $edgeBarEndPosition;
        }

        &--center.people-river__color-bar--skinny {
            left: $centerBarEndPositionRightSkinny;
        }

        &--center.people-river__color-bar--wide {
            left: $centerBarEndPositionRightWide;
        }
    }
}

.people-river__color-bars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.people-river__color-bar {
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: all $anim-speed-slow;
}

.is-in-view .people-river__color-bar {
    opacity: 1.0;
}

/** Size variations **/
.people-river__color-bar {
    &--skinny {
        width: $skinny-bar-width;
    }

    &--wide {
        width: $wide-bar-width;
    }
}

/** Position variations **/
.slide-layout--orientation-left .people-river__color-bar {
    &--edge {
        right: calc(#{$edgeBarEndPosition} + 15%);

        &.final-position {
            right: $edgeBarEndPosition;
        }
    }

    &--center.people-river__color-bar--skinny {
        left: calc(#{$centerBarEndPositionLeftSkinny} - 15%);

        &.final-position {
            left: $centerBarEndPositionLeftSkinny;
        }
    }

    &--center.people-river__color-bar--wide {
        left: calc(#{$centerBarEndPositionLeftWide} - 10%);

        &.final-position {
            left: $centerBarEndPositionLeftWide;
        }
    }
}

.slide-layout--orientation-right .people-river__color-bar {
    &--edge {
        left: calc(#{$edgeBarEndPosition} + 15%);

        &.final-position {
            left: $edgeBarEndPosition;
        }
    }

    &--center.people-river__color-bar--skinny {
        left: calc(#{$centerBarEndPositionRightSkinny} + 15%);

        &.final-position {
            left: $centerBarEndPositionRightSkinny;
        }
    }

    &--center.people-river__color-bar--wide {
        left: calc(#{$centerBarEndPositionRightWide} + 10%);

        &.final-position {
            left: $centerBarEndPositionRightWide;
        }
    }
}


/** Color options **/
.people-river__color-bar {

    &--theme-maroon {
        background-color: $color-transparent-maroon;
    }

    &--theme-orange {
        background-color: $color-transparent-orange;
    }

    &--theme-green {
        background-color: $color-transparent-green;
    }

    &--theme-teal {
        background-color: $color-transparent-teal;
    }

    &--theme-light-blue {
        background-color: $color-transparent-light-blue;
    }

    &--theme-blue {
        background-color: $color-transparent-blue;
    }
}
