.video {
    @extend %content-contained;
}

.video__title {
    @extend h3;
    font-size: $font-size-med;
    line-height: $font-size-med-line;
    font-weight: 700;
    margin-top: 10px;
}

@media only screen and (max-width:799px) {
    .video__title{
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
    }
}

.video__caption {
    margin-top: 0px !important;
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
    font-style:italic !important;
}
