.cookie-warning {
	align-items: center;
	align-items: flex-start;
	background-color: $color-dim-70;
	border: 4px solid $color-gray-35;
	color: $color-white;
	display: flex;
	margin: 0;
	max-width: 1250px;
	padding: 20px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	z-index: 11;
	max-height: 100vh;
	overflow-y: auto;
	@include medium-breakpoint {
		left: 0;
		margin: 0 115px;
		width: calc(100% - 230px);
		transform: translateX(0);
	}
	@include small-breakpoint {
		display: block;
		margin: 0;
		width: 100%;
		overflow-y: auto;
		top: 0;
	}

	&.is-hidden {
		display: none;
	}

	p {
		color: $color-white;
	}

	h4 {
		color: $color-white;
		font-size: 16px;
	}
}

.cookie-warning__form-container {
	display: none;
}

.cookie-warning__button {
	white-space: nowrap;
	background-color: $color-brand-dark-blue;
	&:hover {
		color: $color-brand-dark-blue;
		background-color: $color-white;
		outline: 2px solid $color-brand-dark-blue;
	}
	@include small-breakpoint {
		min-width: 380px;
	}
}

.cookie-warning__button-wrapper {
	margin: 10px 0 0;
}

.cookie-warning__summary {
	width: 100%;
	display: block;

	.cookie-warning__table {
		color: $color-white;
		font-size: 15px;
		width: 100%;
		display: none;

		th {
			background-color: $color-brand-light-blue;
			padding: 5px;
		}

		td {
			background-color: $color-transparent-blue;
			padding: 5px 10px;
			font-weight: 400;
		}
		// tr:not(.cooking-warning__table__heading) { display: none }
	}

	h2 {
		line-height: 100%;
		margin-bottom: 10px;
		color: $color-white;
	}
	@include small-breakpoint {
		margin-bottom: 20px;
	}

	a {
		border-color: lighten($color-brand-teal, 65);
		color: lighten($color-brand-teal, 65);
		display: inline-block;
		font-weight: 400;
		line-height: 1;

		&:hover {
			border-color: lighten($color-brand-teal, 65);
			color: lighten($color-brand-teal, 65);
			font-weight: 400;
		}
	}

	p {
		max-width: 1050px !important;
	}
}

.cookie-warning__form {
	display: inline-block;
	padding: unset !important;
	width: unset !important;
}

.cookie-warning__form__input {
	overflow: hidden;
	margin-bottom: 5px;

	&:last-of-type {
		margin-bottom: 0;
	}

	label {
		float: right;
		width: calc(100% - 25px);
		color: $color-white;
		text-transform: inherit;
		font-weight: 400;
		font-size: $font-size-small;

		span {
			font-weight: 600;
			// border-bottom: $color-brand-light-blue 1px solid;
		}
	}

	input.cookie-warning__checkbox {
		height: 20px;
		width: 20px;
		min-width: 20px;
		display: inline;
		position: absolute;
		opacity: 0;
		cursor: pointer;
		z-index: 2;
	}

	.cookie-warning__checkbox--fake {
		height: 20px;
		width: 20px;
		min-width: 20px;
		display: inline-block;
		vertical-align: middle;
		// background-color: $color-white;
		background: linear-gradient(to bottom, rgba(226,226,226,1) 0%,rgba(219,219,219,1) 50%,rgba(209,209,209,1) 51%,rgba(254,254,254,1) 100%);
		position: relative;
		z-index: 1;
		border-radius: 2px;
		border: #969494 solid 1px;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 5px;
			top: 1px;
			width: 5px;
			height: 9px;
			border: solid #424242;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(40deg);
			-ms-transform: rotate(40deg);
			transform: rotate(40deg);
		}
	}
}

input.cookie-warning__checkbox:checked ~ .cookie-warning__checkbox--fake:after {
	display: block;
}
input.cookie-warning__checkbox:disabled ~ .cookie-warning__checkbox--fake {
	background: linear-gradient(to bottom, rgba(226,226,226,1) 0%,rgba(155,155,155,1) 50%,rgba(155,155,155,1) 52%,rgba(254,254,254,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	&:after {
		opacity: .7;
	}
}
.js-cookie-warning__table-button {
	&:focus {
		outline: none;
		border: $color-brand-darker-light-blue solid 2px;
		border-bottom: 0;
	}
}

.js-cookie__display-tabs {
	background-color: $color-transparent-blue;
	margin-bottom: 15px;

    &.is-hidden {
		display: none;
	}
}

button.btn.js-cookie__display-tabs.cookie__display-tabs{
	color: $color-white;
	background-color: $color-brand-dark-blue;
	&:hover {
		color: $color-brand-dark-blue;
		background-color: $color-white;
		outline: 2px solid $color-brand-dark-blue;
	}
}

button.cookie-warning__button.btn.btn--primary-brand.js-cookie-warning__button.js-cookie-warning__button--submit-all
{
	color: $color-white;
	background-color: $color-brand-light-blue-acc;
	&:hover {
		color: $color-brand-light-blue-acc;
		background-color: $color-white;
		outline: 2px solid $color-brand-light-blue-acc;
	}
}

button.js-cookie-warning__button--submit.cookie-warning__button.btn.btn--primary-brand{
	color: $color-white;
	background-color: $color-brand-light-blue-acc;
	&:hover {
		color: $color-brand-light-blue-acc;
		background-color: $color-white;
		outline: 2px solid $color-brand-light-blue-acc;
	}
}

.cookie-tabs {
	li {
		list-style-type: none;
		display: inline-block;
	}

	.cookie__tab {
		background-color: $color-transparent-blue;

		&.is-active,
		&:hover {
			background-color: $color-brand-light-blue;
		}
	}
}

.cookie-warning__close {
	position: absolute;
	top: 10px;
	right: 20px;
	button {
		color: $color-white;
	}
}

.js-cookie-tab-content {
	display: none;

	&.is-visible {
		display: block;
		border: $color-brand-light-blue 1px solid;
		padding: 20px 10px;
		background-color: $color-brand-darker-light-blue;
	}
}

.js-cookie-warning__button--select-all,
.js-cookie-warning__button--submit {
	display: none;
}

.required-cookie-discliamer-text {
	color: $color-white;
	font-size: 15px;
	font-weight: 400;
}

.js-cookie-warning__button--reject {
	background-color: #0475BC;
	white-space: normal;
}

.js-cookie-warning__button--reject:hover {
	color: #0475BC;
	background-color: #fff;
	outline: 2px solid #0475BC;
}

.update-customize-btn-ui {
	background-color: #0475BC !important;
}

.update-customize-btn-ui:hover {
	color: #0475BC !important;
	background-color: #fff !important;
	outline: 2px solid #0475BC !important;
}
