.search-mobile__form {
	position: relative;

	label {
		margin: 0;
		padding: 0;
	}

	label span {
		position: absolute;
			left: -99999999999px;
	}
}

.search-mobile__form-wrapper {
	border-bottom: 2px solid $color-white;
	padding: 10px 0 20px 0;
}

.search-mobile__searchbox {
	background-color: $color-transparent-white-50;
	border: none;
	color: $color-white;
	padding-right: 41px;
	min-width: 0;
	width: 100%;

	&:focus {
		border: 1px dotted $color-white;
	}

	&::placeholder {
		color: $color-white;
		font-size: $body-cdmsmith-font-size;
		font-weight: 700;
		text-transform: uppercase;
		opacity: 1;
	}
}


.search-mobile__search-button {
	background-color: transparent;
	border: none;
	color: $color-white;
	font-size: $font-size-small;
	height: 41px;
	line-height: 41px;
	padding: 0;
	position: absolute;
		top: 0;
		right: 0;
	width: 41px;

	.fa {
		color: $color-white;
	}

	&:focus {
		border: 1px dotted $color-white;
	}
}
