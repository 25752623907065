.downloads-button__help-text {
    margin-bottom: 10px;
}

.downloads-button__button{
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    outline: 2px solid $color-brand-light-blue-acc;
  }
}
