.dropdown-menu {
  display: block;
  position: relative;
  left: -20px;
  top: 10px;
  box-shadow: 0 0 15px $color-dim-15;
  @include small-breakpoint {
    box-shadow: none;
    left: 0;
    top: 0;
  }
}

.dropdown-menu__overview {
  display: block;
  padding: 15px 20px;
  @include small-breakpoint {
    background-color: transparent;
    background-image:
      linear-gradient(90deg, $color-brand-light-blue 0, transparent 0);
      background-repeat: no-repeat;
    // padding: 10px 0;
    padding: 10px 0 0 0;
    // border-bottom: 2px solid $color-white;
    // text-transform: uppercase;
    transition: $standard-transition;
  }

  &.is-active {
    @include small-breakpoint {
      padding: 10px 0 10px 0;
    }
  }

  a {
    @extend %link-on-hover-only;
    font-size: $body-cdmsmith-font-size;
    font-weight: 400;
    padding-bottom: 2px;
    @include small-breakpoint {
      font-weight: 600;  
    }
  }
}

.dropdown-menu__overview:hover {
  @include small-breakpoint {
    // border-bottom: 2px solid $color-white;
    background-image:
      linear-gradient(90deg, $color-brand-light-blue 10px, transparent 10px);
      background-repeat: no-repeat;
    padding-left: 5px;
    background-color: $color-transparent-white-10;
  }

  a {
    @include small-breakpoint {
      border: none;
    }
  }
}

.dropdown-menu__overview.is-active {
  @include small-breakpoint {
    // border-bottom: 2px solid $color-white;
    border-left: 10px solid $color-brand-light-blue;
    background-color: $color-transparent-white-10;
    padding-left: 5px;

    &:hover {
      background-color: $color-transparent-white-10;
    }
    
  }
}
// Header themes

.header--no-banner {
  .dropdown-menu {
    background-color: $color-brand-dark-blue;
    @include small-breakpoint {
      background-color: transparent;
    }
  }

  .dropdown-menu__overview {
    background-color: $color-brand-light-blue;
    @include small-breakpoint {
      background-color: transparent;
    }

    &.is-active {
      @include small-breakpoint {
        background-color: $color-transparent-white-10;
      }
    }
  }

  .dropdown-menu__overview a {
    color: $color-white;
    @include small-breakpoint {
      background-color: transparent;
      font-weight:700;
    }
  }

  .dropdown-menu__overview:hover a {
    border-bottom: 2px solid $color-white;

  }

  .dropdown-menu__overview.is-active a {
    border-bottom: 1px solid $color-white;
    @include small-breakpoint {
      border: none;
    }
  }

  .dropdown-menu__overview.is-active:hover a {
    border-bottom: 2px solid $color-white;
    @include small-breakpoint {
      border: none;
    }
  }
}

.header--over-banner {
  .dropdown-menu {
    background-color: $color-white;
    @include small-breakpoint {
      background-color: transparent;
      border-bottom: 2px solid $color-white;
      padding-bottom: 10px;
    } 
  }

  .dropdown-menu__overview {
    background-color: $color-gray-02;
    @include small-breakpoint {
      background-color: transparent;
    }

    &.is-active {
      @include small-breakpoint {
        background-color: $color-transparent-white-10;
      }
    }
  }

  .dropdown-menu__overview a {
    color: $color-brand-light-blue;
    font-weight: bold;
    @include small-breakpoint {
      color: $color-white;
      font-weight: 700;
    }
  }

  .dropdown-menu__overview:hover a {
    border-bottom: 2px solid $color-brand-light-blue;
    @include small-breakpoint {
      border: none;
    }
  }

  .dropdown-menu__overview.is-active a {
    border-bottom: 1px solid $color-brand-light-blue;
    @include small-breakpoint {
      border: none;
    }
  }

  .dropdown-menu__overview.is-active:hover a {
    border-bottom: 2px solid $color-brand-light-blue;
    @include small-breakpoint {
      border: none;
    }
  }
}
