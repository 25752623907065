/*------------------------------------*\
    #SCSS FILE IMPORTS
\*------------------------------------*/

/** Vendors **/
@import "vendor/slick";
@import "vendor/slick-theme";

/** Abstracts **/
@import "abstracts/colors";
@import "abstracts/breakpoints";
@import "abstracts/layout";
@import "abstracts/mixins";
@import "abstracts/typography";
@import "abstracts/placeholders";
@import "abstracts/symbols";
@import "abstracts/animation";

/** Base styles **/
@import "base/reset"; /* first of base styles */
@import "base/body";
@import "base/accessibility";
@import "base/paragraphs";
@import "base/headings";
@import "base/section-title";
@import "base/links-and-buttons";
@import "base/forms";
@import "base/sliders";
@import "base/experience-editor";
@import "base/section";
@import "base/icons";
@import "base/main";

/** Global Components **/
@import "global-components/header";
@import "global-components/logo";
@import "global-components/nav";
@import "global-components/dropdown-menu";
@import "global-components/menu-panel";
@import "global-components/mega-menu";
@import "global-components/footer";
@import "global-components/footer-contact-us";
@import "global-components/footer-nav";
@import "global-components/footer-social";
@import "global-components/newsletter-signup";
@import "global-components/legal-links";
@import "global-components/copyright";
@import "global-components/back-to-top";
@import "global-components/region-selector";
@import "global-components/cookie-warning";
@import "global-components/search-takeover";
@import "global-components/search-mobile";
@import "global-components/addtoany-share";
@import "global-components/contrast-toggle";


/** Banner Components **/
@import "components/banners/banner";
@import "components/banners/hero-banner";
@import "components/banners/quote-banner";
@import "components/banners/cta-banner";

/** Container Components **/
@import "components/containers/split-container";
@import "components/containers/rail-container";

/** People River **/
@import "components/people river/people-river";
@import "components/people river/layouts";
@import "components/people river/color-bars";
@import "components/people river/elements";
@import "components/people river/slider-caption";
@import "components/people river/slider-cta";
@import "components/people river/slider-quote";

/* Search Components */
@import "components/search/search";
@import "components/search/search-facets";
@import "components/search/search-box";
@import "components/search/search-results";
@import "components/search/search-item";
@import "components/search/search-tags";

/** Other Components **/
@import "components/image";
@import "components/video";
@import "components/teaser-block";
@import "components/accordion-gallery";
@import "components/related-experts";
@import "components/breadcrumbs";
@import "components/title-and-summary";
@import "components/teaser-block";
@import "components/rte";
@import "components/pullquote";
@import "components/event-promo";
@import "components/cta-with-image";
@import "components/cta-without-image";
@import "components/expert-quote-promo";
@import "components/what-we-do";
@import "components/downloads-button";
@import "components/area-of-focus";
@import "components/photo-caption";
@import "components/focus-area-section";
@import "components/latest-news";
@import "components/large-carousel";
@import "components/statistics";
@import "components/person-focus";
@import "components/featured-people";
@import "components/full-width-promo";
@import "components/opportunities";
@import "components/left-page-navigation";
@import "components/email-form";
@import "components/offices";
@import "components/offices-map";
@import "components/social-share";
@import "components/social-media-latest-news";
@import "components/expert-contact-form";

/** Other **/
@import "vendor/masking-input";
@import "vendor/mask";

/** Style Guide **/
@import "components/style-guide";

/** Helper for print styles (show go last) **/
@import "base/print-exclusions";
