// awful selector due to css specificity issues
.at-expanding-share-button.at-expanding-share-button[data-position="bottom-right"] {
	right: 20px;
	bottom: 80px;
	box-shadow: none;

	@include small-breakpoint {
		bottom: 50px;
	}


	.at-expanding-share-button-toggle-bg {
		height: 50px;
		width: 50px;
		opacity: .5;
		transition: opacity .2s;

		&:hover {
			opacity: 1;
		}

		@include small-breakpoint {
			height: 30px;
			width: 30px;
		}

		span {
			height: 100%;
			width: 100%;

			@include small-breakpoint {
				 background-size: 20px;
			}
		}
	}

	a {
		display: inline-block;
		border: 0;
	}
}

.at-expanding-share-button-mobile.at-expanding-share-button .at-icon-wrapper {

	@include small-breakpoint {
		height: 30px;
		width: 30px;
	}

	.at-icon {
		height: 25px;
		width: 25px;
	}
}

.at-expanding-share-button-toggle .at-expanding-share-button-toggle-btn {
	overflow: visible !important; // ugh, sorry. fixes weird focus in FF. can't figure out another way.
}

.addtoany-new-window-warning {
	position: relative;

	.fa-external-link {
		display: block;
		position: absolute;
	    right: -5px;
	    bottom: -5px;
	    color: $color-white;
	    font-size: $font-size-small;
	    width: 15px;
	    height: 15px;
	    z-index: 1;
	    opacity: 0;
	    transition: $standard-transition;
	}

	.fa-external-link::before {
		position: absolute;
		z-index: 2;
	}

	.fa-external-link::after {
		content: "";
		position: absolute;
		bottom: -3px;
		right: -2px;
		display: block;
		width: 23px;
		height: 23px;
		border-radius: 50%;
		background-color: $color-brand-light-blue;
	    box-shadow: 0 2px 4px 0 $color-dim-30;
		z-index: 1;
	}
}

.addtoany-new-window-warning:hover,
.addtoany-new-window-warning:focus,
.at-expanding-share-button-toggle-btn:hover,
.at-expanding-share-button-toggle-btn:focus {
	opacity: 1.0;

	.fa-external-link {
		opacity: 1.0;
	}
}


.at-expanding-share-button-mobile-container {
	.addtoany-new-window-warning {

		.fa-external-link {
			display: none;
		}
	}
}


.a2a_floating_style a{
	border-bottom: none;
}