.styleguide {
    padding: 40px 5%;
    background-color: $color-gray-02;

    header {
        margin-bottom: 30px;
        text-align: center;
    }

    section {
        @extend %content-contained;
        padding: 20px;
        margin-bottom: 50px;
        background-color: $color-white;
        border-radius: 5px;
    }

    .section--horizontal {
        display: flex;
        margin-bottom: 30px;

        > div {
            min-width: 30%;
        }

        @include small-breakpoint {
            flex-wrap: wrap;

            > div {
                min-width: 100%;
            }
        }
    }

    .section__intro {
        margin-bottom: 20px;
    }

    .section__title {
        margin-bottom: 0;
        font-size: 24px;
        color: $color-gray-73;
    }

    .sub-section {
        margin-bottom: 30px;

        .section--horizontal & {
            margin-right: 30px;
        }
    }

    .sub-section__title {
        padding-bottom: 0;
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 25px;
        color: $color-gray-65;
        border-bottom: 1px solid $color-gray-15;
    }

    .note {
        margin-bottom: 10px;
        font-size: 15px;
        font-style: italic;
        color: $color-text-subtle;
    }

    .colors {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        list-style: none;

        @include medium-breakpoint {
            flex-wrap: wrap;
        }
    }

    .swatch {
        flex-basis: 200px;
        flex-grow: 0;
        flex-shrink: 1;
        padding: 5px;
        margin: 5px;
        border: 1px solid $color-gray-15;
        border-radius: 8px;

        @include small-breakpoint {
            flex-basis: 100%;
        }
    }

    .swatch__color {
        display: block;
        height: 50px;
        margin-bottom: 0.3em;
        border-radius: 5px;
    }

    .swatch__color--transparent {
        background: repeating-linear-gradient(
          45deg,
          #606dbc,
          #606dbc 10px,
          #465298 10px,
          #465298 20px
        );
    }

    .swatch__label {
        font-size: 14px;
        color: $color-gray-65;
    }

    .list-item__heading {
        display: inline;
        margin-right: 10px;
        font-size: 14px;
        color: $color-gray-50;
    }

    .font,
    .h-tag-list {
        list-style: none;
    }

    .font-sans {
        font-family: $font-sans;
    }

    .font-serif {
        font-family: $font-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 15px;
    }

    .buttons {
        .btn {
            margin-right: 20px;
        }
    }

    .special-headings *:not(.sub-section__title) {
        margin-bottom: 15px;
        margin-left: 0;
    }

    .styled-list {
        margin-left: 20px;
    }

    .dark-background {
        display: inline-block;
        margin-right: 10px;
        padding: 20px;
        background-color: $color-gray-50;
    }
}
