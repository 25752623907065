.hero-banner__content {
    justify-content: flex-end;
}

.hero-banner__copyright-container {
    width: 50%;

    .banner--has-small-notch & {
        position: absolute;
        bottom: 0;
        // will need to go right if notch is on left (need JS, i think)
    }

    @include medium-breakpoint {
        width: 75%;

        .banner--has-small-notch & {
            position: static;
        }
    }

    @include small-breakpoint {
        width: 100%;
    }
}

.hero-banner__headline {
    width: 50%;
    font-size: $font-size-large;
    font-weight: 700;
    line-height: $font-size-large-line;
    color: $color-white;
    padding-bottom: 20px;

    .banner--has-small-notch & {
        padding-bottom: 0;
    }

    @include medium-breakpoint {
        width: 75%;
        padding-bottom: 40px;

        .banner--has-small-notch & {
            padding-bottom: 40px;
        }
    }

    @include small-breakpoint {
        width: 100%;
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }
}


// Handles the notch option (notch will be 30% content well width)
.hero-banner__outer-wrapper.banner--has-small-notch ~ .split-container:nth-of-type(2) {
    z-index: 1;

    @include medium-breakpoint {
        z-index: auto;
    }

    .split-container__inner--30 {
        margin-top: -#{$skinny-notch-height + $banner-bottom-margin};
        background-color: $color-white;
        min-height: #{$skinny-notch-height + $banner-bottom-margin};

        @include medium-breakpoint {
            // no notch on medium
            margin-top: 0;
        }
    }
}
