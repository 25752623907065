.menu-panel {
  list-style: none;
  padding: 20px;
  @include small-breakpoint {
    display: block;
    padding-top: 0;
    width: 100%;
    padding: 0;
  }
}

.menu-panel li {
  padding-bottom: 15px;
  @include small-breakpoint {
    padding-bottom: 0;
  }
}

.menu-panel__heading {
  display: block;
  padding: 15px 20px;
  margin: -20px -20px 20px;
  @include small-breakpoint {
    transition: $standard-transition;
    background-color: transparent;
    padding: 10px 0;
    margin: 0;
    // border-bottom: 2px solid $color-white;
    // text-transform: uppercase;
  }
}

.menu-panel__heading.is-active {
  @include small-breakpoint {
    // border-bottom: 2px solid $color-white;
    background-image:
      linear-gradient(90deg, $color-brand-light-blue 0, transparent 0);
      background-repeat: no-repeat;
    padding-left: 5px;
    background-color: $color-transparent-white-10;

    &:hover {
      background-color: $color-transparent-white-10;
    }
  }
}

.menu-panel__heading a {
  @extend %link-on-hover-only;
  font-weight: 400;
  padding-bottom: 2px;
  @include small-breakpoint {
    color: $color-white;
    font-weight: normal;

    &:hover {
      border: 0;
    }
  }
}

.menu-panel__heading.is-active a {
  @include small-breakpoint {
    border-bottom: 0;
    border-left: 0;
    padding-left: 0;
  }
}

.menu-panel__link {
  @extend %link-on-hover-only;
  font-size: $body-cdmsmith-font-size;
  font-weight: 400;
  line-height: 20px;
  text-transform: unset;
  

  @include small-breakpoint {
    // border-bottom: 1px solid $color-white;
    display: block;
    line-height: 1;
    padding: 5px 0 7px 30px;
    background-image:
      linear-gradient(90deg, $color-brand-light-blue 0, transparent 0);
    background-repeat: no-repeat;
    // text-transform: uppercase;
    transition: $standard-transition;
  }

  &:hover {
    @include small-breakpoint {
      // border-bottom: 1px solid $color-white;
      // border-left: 10px solid $color-brand-light-blue;
    background-image:
    linear-gradient(90deg, $color-brand-light-blue 10px, transparent 10px);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-color: $color-transparent-white-10;
    border-bottom: 0;
    }
  }
}

.menu-panel__link.is-active {
  @include small-breakpoint {
    // border-bottom: 1px solid $color-white;
    // border-left: 10px solid $color-brand-light-blue;
    background-color: $color-transparent-white-10;
    background-image:
      linear-gradient(90deg, $color-brand-light-blue 10px, transparent 10px);
    background-repeat: no-repeat;
    padding-left: 20px;
  }

  &:hover {
    @include small-breakpoint {
      background-color: $color-transparent-white-10;
    }
  }
}
// Header themes

.header--no-banner {
  .menu-panel__heading {
    background-color: $color-brand-light-blue;
    color: $color-white;
    @include small-breakpoint {
      background-color: transparent;
    }
  }

  .menu-panel__heading.is-active {
    @include small-breakpoint {
      background-color: transparent;
    }
  }

  .menu-panel__heading a {
    color: $color-white;
  }

  .menu-panel__heading.is-active a {
    border-bottom: 1px solid $color-white;
  }

  .menu-panel__heading.is-active:hover a {
    border-bottom: 2px solid $color-white;
  }

  .menu-panel__heading:hover a {
    border-bottom: 2px solid $color-white;
  }

  .menu-panel__link {
    color: $color-white;
    @include small-breakpoint {
      // border-bottom: 1px solid $color-white;
    }
  }

  .menu-panel__link.is-active {
    border-bottom: 1px solid $color-brand-light-blue;
    @include small-breakpoint {
      // border-bottom: 1px solid $color-white;
    }
  }

  .menu-panel__link.is-active:hover {
    border-bottom: 2px solid $color-brand-light-blue;
    @include small-breakpoint {
      // border-bottom: 1px solid $color-brand-light-blue;
    }
  }
}

.header--over-banner {
  .menu-panel__heading {
    background-color: $color-gray-02;
    color: $color-brand-light-blue;
    @include small-breakpoint {
      background-color: transparent;
      color: $color-white;
      padding-bottom: 10px;
    }
  }

  .menu-panel__heading.is-active {
    @include small-breakpoint {
      background-color: transparent;
    }
  }

  .menu-panel__heading a {
    color: $color-brand-light-blue;
    font-weight: bold;
    @include small-breakpoint {
      font-weight: normal;
      color: $color-white;
    }
  }

    //.menu-panel__heading:hover a {
     //border-bottom: 2px solid $color-brand-light-blue;
   // }

    //.menu-panel__heading.is-active a {
    // border-bottom: 1px solid $color-brand-light-blue;
    //}

    //.menu-panel__heading.is-active:hover a {
    // border-bottom: 2px solid $color-brand-light-blue;
    //}

  .menu-panel__link {
    color: $color-brand-light-blue;
    font-weight: bold;
    @include small-breakpoint {
      color: $color-white;
      font-weight: normal;
      // border-bottom: 1px solid $color-white;
    }
  }

  .menu-panel__link.is-active {
    border-bottom: 1px solid $color-brand-dark-blue;
    @include small-breakpoint {
      // border-bottom: 1px solid $color-white;
    }
  }

  .menu-panel__link.is-active:hover {
    border-bottom: 2px solid $color-brand-dark-blue;
    @include small-breakpoint {
      border-bottom: 1px solid $color-brand-dark-blue;
    }
  }
}
@include small-breakpoint {
  .nav__dropdown {
    .dropdown-menu {
      & > div:first-child, & > ul li:first-child {
        padding-left: 24px;
      }
      & > ul li:not(:first-child) a{
        padding-left: 42px;
      }
      & > div:nth-child(2) {
        & > ul > li:first-child {
          padding-left: 24px;
        }
        & > ul > li > a {
          padding-left: 45px
        }
      }

      .menu-panel__link {
        padding-left: 15px;
      }

      .mega-menu {
        .menu-panel__heading {
          color: $color-opacity-white
        }
      }
    }
  }
}
