.left-page-navigation {
	background-color: $alt-background-color;
}

.left-page-navigation__item {
	margin-bottom: 8px;
	margin-left: 10px;

	&:last-child {
		margin-bottom: 10px;
	}

	a {
		border-bottom-width: 0;
		color: $color-brand-light-blue-font-rd !important;
		font-weight: 700 !important;
		font-size: $font-size-small-2;
		
		@include small-breakpoint {
			font-size: $body-cdmsmith-font-size;
		  }
	}
}

.left-page-navigation__items {
	list-style-type: none;
}

.left-page-navigation__title {
	color: $color-gray-35;
	font-size: $font-size-small;
	line-height: $font-size-small-line;
	letter-spacing: 0.2px;
	margin-bottom: 20px;
	text-transform: uppercase;
}

