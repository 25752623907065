.cta-banner__content {
    justify-content: center;

    @include small-breakpoint {
        padding-top: 0;
    }
}

.cta-banner__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    max-width: 65%;
    padding-bottom: 40px;

    @include medium-breakpoint {
        max-width: 80%;
        padding-bottom: 0;
    }

    @include small-breakpoint {
        max-width: 100%;
    }

    * {
        max-width: 100%;
    }
}

.cta-banner__headline {
    font-size: $font-size-large-2 !important;
    font-weight: 700;
    line-height: $font-size-large-2-line !important;
    color: $color-white;

    @include medium-breakpoint {
        font-size: $font-size-large-2;
        line-height: $font-size-large-2-line;
    }

    @include small-breakpoint {
        font-size: $font-size-med-3;
        line-height: $font-size-med-3-line;
    }
}

.cta-banner__summary {
    font-family: $font-cdmsmith;
    font-size: $font-size-small-3 !important;
    line-height: $font-size-small-3-line !important;
    color: $color-white;
    margin-top: 30px;

    @include medium-breakpoint {
        margin-top: 15px;
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
    }

    @include small-breakpoint {
        margin-top: 10px;
        font-size: $font-size-small-2;
        line-height: $font-size-small-2;
    }
}

.cta-banner__button {
    margin-top: 30px;
    background: $color-brand-light-blue-acc;

    &:hover {
      color: $color-brand-light-blue-acc;
      background-color: $color-white;
      outline: 2px solid $color-brand-light-blue-acc;
    }

    @include medium-breakpoint {
        margin-top: 15px;
    }

    @include small-breakpoint {
        width: auto;
        margin-bottom: 0;
    }
}

@media only screen and (max-width:799px) {
    .cta-banner__headline{
        font-size:$font-size-med-3 !important;
        line-height: $font-size-med-3-line !important;
    }

    .cta-banner__summary {
        font-size: $font-size-small-2 !important;
        line-height: $font-size-small-2-line !important;
    }
}
