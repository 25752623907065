.header {
    position: relative;
    display: block;
    width: 100%;
    height: $header-height;

    @include small-breakpoint {
        height: $header-height-small;
        background-color: $color-transparent-blue;
    }
}

.header--no-banner {
    background-color: $color-white;
    margin-bottom: 20px;

    &::after {
        @include medium-and-up(block);
        @include linear-gradient-background;
        position: absolute;
        z-index: 0;
        display: block;
        width: 100%;
        height: 20px;
        content: "";
        opacity: 0.1;
    }
}

.header--over-banner {
    z-index: 10;
    width: 100%;
    background-color: $color-transparent;
}


