.social-media-latest-news {
	@extend %content-contained;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.social-media-latest-news__item {
	display: flex;
	margin-bottom: 50px;
	position: relative;
	width: calc(50% - 30px);

	@include small-breakpoint {
		width: 100%;
	}
}

.social-media-latest-news__cover-link {
	border-bottom: 0;
	height: 100%;
	position: absolute;
		top: 0;
		left: 0;
	width: 100%;

	&:hover {
		border-bottom: none;
	}
}

.social-media-latest-news__info {
	color: $color-gray-35;
	width: 60px;
	margin-right: 12px;
	flex-shrink: 0;
	flex-grow: 0;
}

.social-media-latest-news__image {
	height: auto;
	margin-top: 5px;
	margin-bottom: 8px;
	object-fit: cover;
	width: 56px;
}

.social-media-latest-news__icon {
	height: auto;
	margin-right: 4px;
	object-fit: cover;
	position: relative;
		top: -1px;
	vertical-align: middle;
	width: 14px;
}


.social-media-latest-news__message {
	.fa {
		color: $color-gray-87;
		display: none;
	}

	.social-media-latest-news__cover-link:focus ~ & {
		.fa {
			display: inline-block;
		}
	}
}