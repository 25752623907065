/*------------------------------------*\
    #ANIMATION
\*------------------------------------*/

// Duration
$anim-speed-quick: 0.2s;
$anim-speed-normal: 1s;
$anim-speed-slow: 2s;
$anim-speed-very-slow: 5s;

// Easing
$anim-ease: ease;

// Transitions
$standard-transition: all $anim-speed-normal;