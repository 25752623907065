.newsletter-signup {
    @include medium-breakpoint {
        order: 2;
    }
}



.newsletter-signup__heading {
    @extend .heading--small;
    font-weight: 400;
    color: $color-white;

    @include medium-breakpoint {
        font-weight: 700;
        font-size: $font-size-small-2;
    }
}

@media only screen and (max-width:799px) {
    .newsletter-signup__heading{
        font-size: $font-size-small;
    line-height: $font-size-small-line;
    }
}

.newsletter-signup__subheading {
    margin-top: 20px;
    color: $color-white;
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    font-weight: 400;
}

.newsletter-signup__button-wrapper {
    text-align: left;
    margin-top: 15px;
}

.newsletter-signup__button {
    min-width: 205px;
}

a.newsletter-signup__button.btn.btn--blue{
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    outline: 2px solid $color-brand-light-blue-acc;
  }
}
