@mixin visually-hidden {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;

    /* simpler alternative ??:
        position: absolute;
        left: -999em;
    */
}
