$slider-quote-mark-size: 150px;

/* Experience Editor */
.slider-quote {
    .scWebEditInput {
        position: relative;
        z-index: 10; // to make sure quotation marks don't cover input field
    }
}

.slider-quote {
    position: relative;
    align-self: center;
    width: 100%;

    @include medium-breakpoint {
        align-self: flex-end;
        padding-bottom: 50px;
    }


    @include small-breakpoint {
      align-self: flex-end;
      padding-bottom: 20px;
  }
}

.slider-quote__content {
    position: relative;
    width: 65%;
    padding: 75px 60px 20px;
    color: $color-white;

    @include medium-breakpoint {
        left: 0;
        width: 100%;
        padding: 25px 10px 10px;
    }

    @include small-breakpoint {
        left: 0;
        width: 100%;
        padding: 25px 10px 10px;
    }
}

.slider-quote__quote {
    @include text-shadow($x-position: 0, $y-position:0, $blur-radius:12px, $color: $color-black);
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
    letter-spacing: 1px;
    margin-bottom: 25px;
    position: relative;

    @include medium-breakpoint {

        font-size: $font-size-med-2;
        line-height: $font-size-med-2-line;
        margin-bottom: 15px;
    }

    @include small-breakpoint {
        font-size: $font-size-small-3;
        line-height: $font-size-small-3;
        margin-bottom: 15px;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: $slider-quote-mark-size;
        height: $slider-quote-mark-size;
        background: url("/assets/build/img/quote.svg") no-repeat center center;
        background-size: $slider-quote-mark-size;
        opacity: 0.35;
        z-index: 0;

        @include medium-breakpoint {
            background-size: $slider-quote-mark-size/2;
            height: $slider-quote-mark-size/2;
            width: $slider-quote-mark-size/2;
        }

        @include small-breakpoint {
            background-size: $slider-quote-mark-size/2;
            height: $slider-quote-mark-size/2;
            width: $slider-quote-mark-size/2;
        }
    }

    &:before {
        line-height: 150px;
        top: calc(#{$slider-quote-mark-size} / -2);
        left: -50px;

        @include medium-breakpoint {
            top: calc(110px / -2);
            left: 0px;
        }

        @include small-breakpoint {
            top: calc(110px / -2);
            left: 0px;
        }
    }

    &:after {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
        line-height: 0;
        right: 15px;
        top: 10px;

        @include medium-breakpoint {
            right: 0;
            top: auto;
        }

        @include small-breakpoint {
            right: 0;
            top: auto;
        }
    }
}

.slider-quote__name,
.slider-quote__title {
    @include text-shadow($x-position: 0, $y-position:0, $blur-radius:12px, $color: $color-black);
    font-weight: bold;
    font-size: $font-size-med;
    line-height: $font-size-med-line;

    @include medium-breakpoint {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }

    @include small-breakpoint {
        font-size: $font-size-small-2;
        line-height: $font-size-small-2-line;
    }
}

.slider-quote__title {
    margin-top: 5px;

    @include medium-breakpoint {
        margin-top: 0;
    }

    @include small-breakpoint {
        margin-top: 0;
    }
}
