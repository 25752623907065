

.legal-links {
    display: block;
    padding-bottom: 20px;

    ul {
        @extend %no-list-style;
    }

    li {
        padding-right: 30px;

        @include medium-breakpoint {
            display: block;
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
        }
    }

    a,
    button {
        border-bottom: 2px solid transparent;
        font-family: $font-cdmsmith;
        font-size: $font-size-small;
        font-weight: 400;
        line-height: $font-size-small;
        color: $color-white;
        padding: 0;
        text-align: left;

        @include medium-breakpoint {
            font-size: $font-size-small;
        }
    }
}

@media only screen and (max-width:799px) {
    .legal-links{
        font-size: $font-size-small;
        line-height: $font-size-small-line;
    }
}