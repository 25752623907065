.mega-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include small-breakpoint {
        display: block;
    }

    .menu-panel {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
    }

    .menu-panel__heading {
        background-color: transparent;
        font-family: $font-cdmsmith-light;
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
        letter-spacing: .08em !important;
        text-transform: uppercase;
        padding: 0;
        white-space: nowrap;
        margin: 0 0 20px 0;

        @include small-breakpoint {
            margin-bottom: 0;
            padding: 5px 0;
            padding-left: 5px;
            display: block;
            white-space: normal;
            text-transform: capitalize;
        }
    }

    .menu-panel__heading:hover a {
        border: none;
    }

    .menu-panel__heading.is-active a {
        @include small-breakpoint {
            border-bottom: 0;
        }
    }
}

// Header themes
.header--no-banner .mega-menu {
    .menu-panel__heading {
        color: $color-white;
    }
}

.header--over-banner .mega-menu {
    .menu-panel__heading {
        color: $color-link;

        @include small-breakpoint {
            color: $color-white;
        }
    }

    .menu-panel__heading a {
        color: $color-gray-73;
        font-weight: normal;

        @include small-breakpoint {
            color: $color-white;
        }
    }
}
