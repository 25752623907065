$x-bar-height-desktop: 50px;
$x-bar-height-mobile: 37px;
.accordion-gallery {
  display: flex;
  align-content: stretch;
  @include medium-breakpoint {
    flex-direction: column;
    height: 85vh;
    min-height: 680px;
  }
  @include small-breakpoint {
    flex-direction: column;
    height: 100vh;
    min-height: 680px;
  }
}

.accordion-slide {
  flex: 1;
  height: 600px;
  position: relative;
  overflow: hidden;
  transition: all 1s ease;
  @include medium-breakpoint {
    width: 100%;
    height: auto;
  }
}


/* Accordion slides have 3 display states:

        1) Preview: This shows on load, and if you click outside of an expanded slide. All slides are even width, images are cropped, preview text shows

        2) Expanded: Shows on click. Only 1 slide at a time can be expanded. ~ 6x width of other slides. Full image and text content shows. In this view, a video may be shown, and will replace other expanded text and image content while it is showing.

        3) Collapsed: Shows if *another* slide is clicked (aka expanded). If one slide is expanded, all others will be collapsed. Skinny cropped image, no text shows.
*/

.accordion-slide--preview {
  .accordion-slide__text-content--preview {
    display: flex;
  }
  .accordion-slide__text-content--expanded,
  .accordion-slide__extended-content-wrap,
  .accordion-slide__close-button {
    display: none;
  }
  &:hover .accordion-slide__color-overlay {
    opacity: 0.8;
  }
}

.accordion-slide--collapsed {
  .accordion-slide__text-content,
  .accordion-slide__close-button,
  .accordion-slide__play-video-wrapper,
  .accordion-slide__extended-content-wrap {
    display: none;
  }
}

.accordion-slide--expanded {
  flex: 6;
  /** NOTE: IF SIZE OF EXPANDED SLIDE IS UPDATED IN SCSS
        IT ALSO NEEDS TO BE UPDATED IN ACCORDION-GALLERY.JS **/
  .accordion-slide__text-content--preview,
  .accordion-slide__color-overlay {
    display: none;
  }
  .accordion-slide__text-content--expanded {
    display: block;
    height: auto;
    @include small-breakpoint {
      height: 100%;
    }
  }
  .accordion-slide__play-video-wrapper {
    display: flex;
    padding-top: $x-bar-height-desktop;
    @include small-breakpoint {
      height: 0;
      padding-top: 0;
    }
  }
  .accordion-slide__extended-content-wrap {
    display: flex;
    height: calc(100% - #{$x-bar-height-desktop});
    margin-top: $x-bar-height-desktop;
    @include small-breakpoint {
      height: 100%;
      margin-top: $x-bar-height-mobile;
    }
  }
}

.accordion-slide--ee {
  position: relative;
  display: block;
  width: 800px;
  height: 500px;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 20px;
  .accordion-slide__video-wrapper {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 60px;
    text-align: center;
  }
  .accordion-slide__play-video::after {
    top: 25px;
  }
  .accordion-slide__text-wrapper {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    padding: 20px;
  }
  .accordion-slide__headline {
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
  }
  .accordion-slide__link {
    min-width: 20px;
  }
}

.accordion-slide__text-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  word-wrap: break-word;
}

.accordion-slide__text-content--preview {
  text-align: center;
  height: auto;
  position: relative;
  top: 300px;
  padding: 10px;
  margin: 0 auto;

  @include medium-breakpoint {
    top: auto;
    // padding: 30px 10px 10px 10px;
    padding: 30px 10px;

  }
  .accordion-slide__type {
    position: absolute;
    top: -15px;
    width: calc(100% - 20px);
    text-align: center;
    @include medium-breakpoint {
      position: static;
      width: 100%;
    }
    @include small-breakpoint {
      font-size: $font-size-small;
    }
  }
  .accordion-slide__headline {
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
    cursor: pointer;
    @include medium-breakpoint {
      font-size: $font-size-med-2;
      line-height: $font-size-med-2-line;
    }

  }
}

.accordion-slide__text-content--expanded {
  justify-content: flex-end;
  width: 100%;
  padding: 20px 20% 20px 30px;
  text-align: left;
  @include medium-breakpoint {
    // padding: 10px;
    padding: 30px 10px 10px 10px;
  }
  .accordion-slide__headline {
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
    @include medium-breakpoint {
      font-size: $font-size-med-2;
      line-height: $font-size-med-2-line;
    }
    @include small-breakpoint {
      font-size: $font-size-small-3;
      line-height: $font-size-small-3-line;
    }
  }
  .accordion-slide__type {
    @include medium-breakpoint {
      font-size: $font-size-small;
      line-height: $font-size-small-line;
    }
    @include small-breakpoint {
      font-size: $font-size-small;
      line-height: $font-size-small-line;
    }
  }
}

.accordion-slide__extended-content-wrap {
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: flex-end;
  @include medium-breakpoint {
    position: absolute;
  }
}

.accordion-slide__type {
  color: $color-gray-15;
  font-size: $font-size-small;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.accordion-slide__headline {
  @extend %no-button-style;
  font-weight: 700;
  color: $color-white;
  word-wrap: break-word;
}

.accordion-slide__summary {
  color: $color-white;
  font-size: $body-cdmsmith-font-size;
  line-height: $body-cdmsmith-line;
  margin-top: 15px;
  word-wrap: break-word;
  @include medium-breakpoint {
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    margin-top: 10px;
  }

}

.accordion-slide__link {
  padding: 5px; // override .btn style
  min-height: 0; // override .btn style
  margin-top: 15px;
  word-wrap: break-word;
  @include medium-breakpoint {
    display: inline-block;
    line-height: 12px;
    padding: 10px 20px;
    margin: 10px auto 0 auto; // override .btn style
    max-width: 80%;
    width: auto;
  }
}

a.accordion-slide__link.btn.btn--white{
  color:  $color-brand-light-blue-acc;
  background-color:$color-white;
  &:hover {
    color:  $color-white;
    background-color:$color-brand-light-blue-acc;
    outline: 2px solid $color-white;
  }
}

.accordion-slide__slide-container {
  position: absolute;
  overflow: hidden; // horizontally & vertically center image in container
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accordion-slide__image {
  @include object-fit(cover, center);
  position: absolute;
  width: 100%;
  height: 100%;
}

.accordion-slide__play-video-wrapper {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.accordion-slide__play-video {
  @extend %no-link-style;
  position: relative;
  cursor: pointer; // this stuff makes the triangle
  &::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 35px solid $color-white;
    opacity: 0.8;
    transition: opacity 0.5s ease;
  }
  &:hover {
    &::after {
      opacity: 1.0;
    }
  } // this stuff makes the circle
  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -27px;
    display: block;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: $color-dim-70;
  }
  @include medium-breakpoint {
    &::after {
      // triangle
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid $color-white;
    }
    &::before {
      // circle
      top: -12px;
      left: -15px;
      width: 40px;
      height: 40px;
    }
  }
  @include small-breakpoint {
    display: none;
  }
}

.accordion-slide__video-container {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: calc(100% - #{$x-bar-height-desktop});
  @include small-breakpoint {
    max-height: calc(100% - #{$x-bar-height-mobile});
  }
}

.accordion-slide__video-container-inner {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.accordion-slide__color-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 1s ease;
}

.accordion-slide__close-button {
  @extend %no-button-style;
  position: absolute;
  top: 0;
  width: 100%;
  height: $x-bar-height-desktop;
  padding: 0 10px 5px 5px;
  text-align: right;
  @include medium-breakpoint {
    padding: 0 6px 0 0;
    height: $x-bar-height-mobile;
  } // the X
  &::after {
    content: $x;
    color: $color-white;
    font-family: $font-cdmsmith;
    font-size: 40px;
    font-weight: normal;
    @include medium-breakpoint {
      font-size: 25px;
    }
  }
  &:focus {
    outline: none; // passing the focus style to the 'X'
    &::after {
      outline-width: 2px;
      outline-style: solid;
      outline-color: Highlight;
      /* WebKit gets its native focus styles. */
      @media (-webkit-min-device-pixel-ratio: 0) {
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  } // the color theme stripe
  &::before {
    content: "";
  }
}

@media only screen and (max-width:799px) {
    .accordion-slide__text-content--preview .accordion-slide__headline{
        font-size: 24px !important;
        line-height: 36px !important;
    }
}

/* slide color theme options */

.accordion-slide__color-overlay,
.accordion-slide__text-content--expanded,
.accordion-slide__close-button,
.accordion-slide--ee .accordion-slide__text-wrapper {
  // DEFAULT IS DARK BLUE IF NO THEME SPECIFIED
  background-color: $color-transparent-blue;
  &.theme-blue {
    background-color: $color-transparent-blue;
  }
  &.theme-grey {
    background-color: $color-dim-60;
  }
  &.theme-light-blue {
    background-color: $color-transparent-darker-light-blue;
  }
  &.theme-teal {
    background-color: $color-transparent-dark-teal;
  }
  &.theme-green {
    background-color: $color-transparent-dark-green;
  }
  &.theme-maroon {
    background-color: $color-transparent-dark-maroon;
  }
  &.theme-orange {
    background-color: $color-transparent-dark-orange;
  }
}
