$color-opacity-white: rgba(250, 250, 250, 0.6);

.nav {
  @extend %content-contained;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  height: 100%;
  max-width: calc(#{$max-content-width} + 20px);
  padding: 0 10px;
  @include small-breakpoint {
    position: inherit;
    height: 100%;
    padding: 5px 15px;
  }
}

.nav a,
.nav button {
  cursor: pointer;
}

.nav .fa-caret-down {
  transition: 0.2s;
  padding-left: 5px;
  color: $color-brand-green;
  @include small-breakpoint {
    float: right;
    font-size: 20px;
  }
}
.fa-caret-right.mobile-only {
  display: none;
  color: $color-brand-green;
  padding-left: 5px;
  font-size: 20px;
  @include small-breakpoint {
    display: inline;
  }
}

.nav .fa-external-link {
  color: $color-white;
}

.nav .fa-search {
  padding-left: 5px;
  color: $color-brand-green;
}
/* desktop logo */
.nav__home {
  display: inline-block;
  position: relative;
  z-index: 2;
  padding: 10px 0;
  max-width: 150px;
  min-width: 80px;
  height: 100%;
  @include small-breakpoint {
    max-height: 65px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
/* mobile logo */
.nav__logo {
  @include small-only;
  margin-top: 6px;
  margin-left: 17px;
  margin-bottom: 20px;
  // width: 105px;
  max-width: 150px;
  padding: 10px 0;
  height: 65px;

  svg {
    height: auto;
  }
}

.nav__toggle {
  @include small-only(inline-block);
  @extend %no-link-style;
  font-size: 30px;
  padding: 10px;
  position: relative;
  z-index: 2;
}

.nav__wrapper {
  @include medium-and-up(block);
  position: relative;
  z-index: 2;
  height: 75px;
  @include small-breakpoint {
    background-color: rgba($color-brand-dark-blue, 0.97);
    display: block;
    height: 100%;
    width: 310px;
    position: absolute;
    top: 0;
    left: -310px;
    z-index: 100;
  }
}

.nav__list {
  display: flex;
  list-style: none;
  @include small-breakpoint {
    display: block;
  }
}

.nav__list--primary {
  justify-content: space-between;
  margin-top: 40px;
  @include small-breakpoint {
    margin: 0;
  }
}

.nav__list--utility {
  justify-content: flex-end;
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  right: 0;
  @include small-breakpoint {
    position: relative;
    padding-left: 15px;
  }
  .fa-caret-right {
    font-size: 14px;
  }
}

.nav__item {
  display: inline;
  margin: 0 10px;
  white-space: nowrap;
  @include small-breakpoint {
    display: block;
    white-space: normal;
  }
}

.nav__item.is-active {
  border-bottom: 2px solid $color-brand-light-blue;
  @include small-breakpoint {
    border-bottom: 0;
  }
}

.nav__item--open {
  .nav__dropdown {
    display: block;
    position: absolute;
    @include small-breakpoint {
      position: relative;
    }
  }

  &.nav__item:last-of-type .nav__dropdown {
    right: 0;
  }

  .fa-caret-down {
    transform: rotate(180deg);
  }

  .nav__menu-trigger {
    color: $color-opacity-white;
  }
}

.nav__item--search {
  /* Search takeover trigger should not exist in mobile... */
  @include small-breakpoint {
    display: none;
  }
}

.nav__item--search-mobile {
  /* ...and mobile search should not appear elsewhere */
  display: none;
  @include small-breakpoint {
    display: block;
  }
}

.nav__item--primary > a,
.nav__item--primary > button {
  @extend .heading--small;
  @include small-breakpoint {
    display: block;
    width: 100%;
    padding: 10px 0 10px 15px;
    text-align: left;
    color: $color-white;
    font-size: $body-cdmsmith-font-size;
    border-bottom: 2px solid $color-white;
  }
}

.nav__item--primary.nav__item--open > button {
  border-bottom: 1px solid $color-white;
  color: $color-opacity-white;

  @include small-breakpoint {
    color: $color-opacity-white;
    border-bottom: 1px solid $color-opacity-white;
  }
}

.nav__item--primary > a:hover {
  @include small-breakpoint {
    border-bottom: 2px solid $color-white;
    background-color: rgba($color-white, 0.1);
  }
}

.nav__item--primary > button:hover {
  @include small-breakpoint {
    border-bottom: 2px solid $color-opacity-white;
    background-color: rgba($color-white, 0.1);
  }
}

.nav__link {
  @extend %link-on-hover-only;
}

.nav__link.is-active {
  border-bottom: 2px solid $color-brand-light-blue;
}

.nav__item--primary {
  button {
    // IE fix to match buttons
    font-family: "Stevie Sans W00", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
    -webkit-font-smoothing: antialiased;
  }
  @include small-breakpoint {
    transition: $standard-transition;
  }
}

// .nav__link--primary:hover {
  // @include small-breakpoint {
  //     border-left: 10px solid $color-brand-light-blue;
  //     padding-left: 5px;
  // }
// }

.nav__link--primary.is-active {
  @include small-breakpoint {
    color: $color-opacity-white;
    border-bottom: 2px solid $color-white;
    // border-left: 10px solid $color-brand-light-blue;
    padding-left: 15px;
  }
}

.nav__link--utility {
  font-size: $font-size-small;
  font-weight: 400;

  .fa-external-link {
    padding-left: 3px;
  }
  @include small-breakpoint {
    color: $color-white;
    display: block;
    padding: 10px 0;
  }
}

.nav__link--utility.is-active,
.nav__link--utility:hover {
  @include small-breakpoint {
    border-bottom: none;
    border-left: 10px solid $color-brand-light-blue;
    padding-left: 5px;
    position: relative;
    left: -15px;
  }
}

.is-active .nav__link--utility:hover {
  @include small-breakpoint {
    background-color: $color-transparent-white-10;
  }
}

.nav__dropdown {
  display: none;
}

.nav__close {
  @include small-only;
  position: absolute;
  top: 0;
  right: 15px;

  &:after {
    content: $x;
    color: $color-white;
    font-size: 40px;
    font-weight: normal;
  }
}

.nav__button {
  @extend %no-button-style;
  line-height: 21px;
  @include small-breakpoint {
    width: 100%;
    text-align: left;
  }
}

.nav__menu-trigger {
  @extend %no-link-style;
  position: relative;
  font-weight: bold;
  transition: $standard-transition;
  white-space: nowrap;
  outline-color: Highlight;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-color: -webkit-focus-ring-color;
  }

  .nav__item:not(.nav__item--primary) &:hover {
    @include small-breakpoint {
      // border-left: 10px solid $color-brand-light-blue;
      // padding-left: 5px;
    }
  }
}
.nav__menu-trigger.is-active {
  display: block;
  @include small-breakpoint {
    // border-bottom: 2px solid $color-white;
    // border-left: 10px solid $color-brand-light-blue;
    padding-left: 5px;
  }

  .nav__item--primary & {
    @include small-breakpoint {
      background-color: transparent;
    }
  }
}

.nav .nav__link--utility.is-active,
.nav .nav__menu-trigger.is-active {
  border-bottom: 2px solid $color-brand-light-blue;
  @include small-breakpoint {
    color: $color-opacity-white;
    border-bottom: 1px solid $color-white;
  }
}
// Mobile Nav Open / Closed states

.is-nav-open {
  .nav {
    position: fixed;
    z-index: 10;
    min-height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .nav-toggle-menu {
    cursor: default;
    opacity: 0;
  }

  .nav__wrapper {
    @include small-breakpoint {
      display: block;
      left: 0;
      transition: 0.5s;
      height: auto;
      min-height: 100%;
    }
  }
}

.is-nav-closed {
  .nav-toggle-menu {
    cursor: pointer;
    opacity: 1;
  }

  .nav__wrapper {
    @include small-breakpoint {
      display: block;
      left: -310px;
    }
  }
}
// Header themes

.header--no-banner {
  .nav__link--utility {
    color: $color-gray-73;
    @include small-breakpoint {
      color: $color-white;
      display: block;
      padding: 10px 0;
    }
  }
}

.header--over-banner {
  .fa-bars,
  .fa-globe {
    color: $color-white;
  }

  .nav__item--primary > a,
  .nav__item--primary > button {
    color: $color-white;

    &.is-active {
      @include small-breakpoint {
        color: $color-opacity-white;
      }
    }
  }

  .nav__item.nav__item--primary.nav__item--open > button {
    color: $color-opacity-white;
  }

  .nav__link--utility {
    color: $color-white;
  }
}
