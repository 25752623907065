.footer {
    display: block;
    padding-top: 50px;
    color: $color-white;
    background: $color-brand-dark-blue;
    width: 100%;

    @include medium-breakpoint {
        padding: 50px 40px 0;
    }
}

.footer__content {
    @extend %content-contained;
    max-width: calc(#{$max-content-width} + 20px);
    padding: 0 10px;

    @include medium-breakpoint {
        padding: 0;
    }
}

.footer__vertical-section {
    padding: 20px 0;

    @include medium-breakpoint {
        padding: 0 0 40px;
    }
}

.footer__vertical-section:not(:last-of-type) {
    border-bottom: 1px solid $color-transparent-white-50;

    @include medium-breakpoint {
        border-bottom: 0;
    }
}

.footer__horizontal-section {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;

    @include medium-breakpoint {
        padding: 0;
    }
    @include small-breakpoint {
        flex-direction: column;
    }
}

.footer__horizontal-section > * {
    flex-basis: 25%;
    flex-grow: 1;

    &:not(:last-child) {
        margin-right: 20px;
    }

    @include medium-breakpoint {
        flex-basis: 50%;

        &:not(:last-child) {
            margin-right: 0px;
        }
    }

    @include small-breakpoint {
    flex-basis: auto;
    }
}

.footer .btn--blue:hover {
    background-color: $color-white;
    color: $color-brand-dark-blue;

    .fa-external-link {
        color: $color-brand-dark-blue;
    }
}
