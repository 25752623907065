$email-form-medium-breakpoint: 1050px;

.email-form {
    @extend %content-contained;
}

.email-form__expansion-container {
    overflow: hidden;  // doing this could potentially cutoff dropdowns.
    position: relative;
    transition: height $anim-speed-normal;

    &:not(.is-visible) {
        height: 0 !important;
    }
}

.email-form__expansion-content {
    position: absolute;
        top: 0;
    transform: translateY(-100%);
    transition: transform $anim-speed-normal;
    width: 100%;

    .is-visible & {
        transform: translateY(0);
    }
}

.email-form__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--legend {
        margin-top: 30px;
    }
}

.email-form__label {
    margin-bottom: 15px;

    &--camel-case {
        margin-bottom: 15px;
        text-transform: none;
    }
}

.email-form__column {
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 30px;

    //IE11 hack for flex-basis
    *::-ms-backdrop, &:nth-last-child(2):first-child ~ & {
      flex-basis: 46%;
    }

    &:not(:first-of-type) {
        margin-left: 2%;
    }

    &--wide {
        flex-basis: 80%;
        margin-bottom: 20px;
    }

    &--full {
		flex-basis: 100%;
        margin-bottom: 20px;

		&--flex {
			display: flex;
		}
    }

    &--submit-clear {
        flex-basis: 60%;
        margin-bottom: 30px;
		display: flex;
		flex-flow: inherit;
		justify-content: center;
		align-content: center;
    }

    &--inline-content {
        display: flex;
    }

    @media (max-width: $email-form-medium-breakpoint) {
        max-width: 100%;

        &,
        &--wide,
        &:not(:first-of-type) {
            flex-basis: 80%;
            margin: 0 auto 30px;
        }

	    &--submit-clear {
			flex-basis: 100%;
			margin-bottom: 30px;
    }

        &--captcha,
        &--submit {
            flex-basis: auto;
        }

        &--inline-content {
            flex-direction: column-reverse;
        }
    }

    @include small-breakpoint {
        &,
        &--wide,
        &:not(:first-of-type) {
            flex-basis: 100%;
            margin: 0 auto 30px;
        }
    }


}

.email-form {
    input:not([type="submit"]):not(.email-form__cancel),
    textarea,
    select,
    .select-list {
        width: 100%;

        @include small-breakpoint {
            min-width: 0;
        }
    }

    textarea {
        min-height: 250px;
    }

	#email-form__consent {
		min-width: 20px !important;
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

    *,
    *:focus,
    *:invalid {
        box-shadow: none;
    }

    *:focus {
        border: 1px solid $color-brand-light-blue;
    }
}

.email-form__webmaster-email {
    white-space: nowrap;
}

.email-form__legend-wrapper {
    &--mobile {
        display: none;
    }

    @media (max-width: $email-form-medium-breakpoint) {
        &--desktop {
            display: none;
        }

        &--mobile {
            display: flex;
            margin-bottom: 20px;
        }
    }
}

.email-form__legend {
    margin-top: 30px;

    @media (max-width: $email-form-medium-breakpoint) {
        margin-top: 15px;
    }
}

.email-form__captcha {
    display: inline-block;
    vertical-align: middle;
    height: 78px;

    &--invisible {
        display: none;
    }

    @media (max-width: $email-form-medium-breakpoint) {
        width: 100%;

        & > * {
            margin: 0 auto;
        }
    }

    @include small-breakpoint {
        transform: scale(0.92);
        -webkit-transform: scale(0.92);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

.email-form__submit,
.email-form__cancel {
    min-width: 150px;
    vertical-align: top;
    margin-right: 30px;

    &:disabled:hover {
        background-color: #707070; // no visibe hover when disabled
    }

    @media (max-width: $email-form-medium-breakpoint) {
        width: 100%;
        margin: 0 auto;
    }
}

.email-form__submit {
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    &:hover {
        color: $color-brand-light-blue-acc;
        background-color: $color-white;
        outline: 2px solid $color-brand-light-blue-acc;
    }

    &.disabled {
        &:hover {
            border-bottom: 0;
        }
    }
}

.email-form__cancel {
    color: $color-white;
    background-color: $color-brand-dark-blue;
    &:hover {
        color: $color-brand-dark-blue;
        background-color: $color-white;
        outline: 2px solid $color-brand-dark-blue;
    }
}

.email-form__message {
    display: none;
    margin-right: 10px;
    line-height: 44px;

    &.active-error {
        display: block;
        border: solid 1px
    }
}

.email-form__message-text {
    display: none;

        &.active-error {
        display: block;
    }
}

.email-form__message-text--error,
.email-form__message-text--email-error,
.email-form__message-text--telephone-error,
.email-form__message-text--message-error {
    color: red;
}

.email-form__message-text--success {
    color: green;
}

label.email-error,
.email-form__message.email-error {
    .email-form__message-text--email-error {
        display: block;
    }
    .email-form__message-text--error,
	.email-form__message-text--telephone-error,
	.email-form__message-text--message-error,
    .email-form__message-text--success {
        display: none;
    }
}
label.telephone-error,
.email-form__message.telephone-error {
    .email-form__message-text--telephone-error {
        display: block;
    }
    .email-form__message-text--error,
	.email-form__message-text--email-error,
	.email-form__message-text--message-error,
    .email-form__message-text--success {
        display: none;
    }
}
label.message-error,
.email-form__message.message-error {
    .email-form__message-text--message-error {
        display: block;
    }
    .email-form__message-text--error,
	.email-form__message-text--email-error,
	.email-form__message-text--telephone-error,
    .email-form__message-text--success {
        display: none;
    }
}
label.error,
.email-form__message.error {
    .email-form__message-text--error {
        display: block;
    }
    .email-form__message-text--email-error,
	.email-form__message-text--telephone-error,
	.email-form__message-text--message-error,
    .email-form__message-text--success {
        display: none;
    }
}
.email-form__message.success {
    .email-form__message-text--success {
        display: block;
    }
    .email-form__message-text--email-error,
	.email-form__message-text--telephone-error,
	.email-form__message-text--message-error,
    .email-form__message-text--error {
        display: none;
    }
}

#accommodationRequestForm,
#individualRightsRequestForm,
#emailForm {
    .email-form__message,
    .email-form__message-text--error,
    .email-form__message-text--email-error,
    .email-form__message-text--telephone-error,
    .email-form__message-text--message-error {
        color: #B02F30;
    }

    .email-form__message {
        padding: 20px 0 20px 40px;
        margin-bottom: 40px;

        li {
            padding-left: 40px;
        }
    }

    .btn {
        &.disabled {
            background: $color-gray-35;
            color: $color-black;
            cursor: default;

            &:hover {
                background: $color-gray-35;
                color: $color-black;
                border-bottom: 0;
            }
        }
    }
}

.split-container--50-50 {
    .email-form__row {
       align-items: center;
       justify-content: center;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: flex-end;
    }

    .email-form__column {
        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 30px;

        &:not(:first-of-type) {
            margin-left: 0;
        }

        //ugly IE11 hack for flex-basis
      *::-ms-backdrop, & {
        flex-basis: 100% !important;
      }
    }
}
