/* Experience Editor */
.experience-editor .people-river__element-wrapper {
    border: 2px solid yellow;

    .scEmptyPlaceholder {
        height: 100%; // overriding webedit.css
    }
}

.people-river__column {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    @include medium-breakpoint {
        width: 80vw;
        // height: 120vw; // want slide to have 2:3 ratio at tablet/mobile
        margin: 0 auto;
    }

    &--skinny {
        flex: 1;
        @include medium-breakpoint {
            flex: 1;
        }
    }

    &--wide {
        flex: 2;
        @include medium-breakpoint {
            flex: 1;
        }
    }

    &--full {
        flex: 1;
        @include medium-breakpoint {
            flex: 1;
        }
    }
}


.people-river__row {
    position: relative;
    width: 100%;
    height: 50%;

    @include small-breakpoint {
        height: auto;
    }


}

.people-river__element-wrapper {
    position: relative;
    width: 100%;
    max-height: 100%;

    &--2x1 {
        @include aspect-ratio(2,1);
    }

    &--2x3 {
        @include aspect-ratio(2,3);
    }

    &--4x3 {
        @include aspect-ratio(4,3);
    }

    // aspect ratios that change at medium
    @include medium-breakpoint {
        &--2x1 {
            @include aspect-ratio(1,1);
        }

        .people-river__column--wide &--4x3 {
            @include aspect-ratio(2,3);
        }
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
