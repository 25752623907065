/*------------------------------------*\
    #SYMBOLS

    note: CSS-encoded unicode symbols

    helpful tools:
    symbol search: www.amp-what.com
    unicode converter: r12a.github.io/apps/conversion
\*------------------------------------*/

$long-dash: "\2013";
$non-break-space: "\00A0";
$copyright: "\00A9";
$trademark: "\2122";
$magnifying-glass: "\01F50D";
$globe: "\1f30f";
$triangle-down: "\25bc";
$carat-up: "\5e";
$carat-right: "\203a";
$heart: "\2764";
$speech-bubble: "\1f4ac";
$two-speech-bubbles: "\1f5ea";
$check: "\2714";
$cross-out: "\2717";
$asterisk: "\2a";
$x: "\d7";

$open-quotation-mark: "\201C";
$close-quotation-mark: "\201D";