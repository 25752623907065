

.featured-person {
  display: flex;
  height: 550px;
  @include small-breakpoint {
    height: auto;
  }
  @include small-breakpoint {
    flex-direction: column;
        display: block;
  }
}

.featured-person__container {
  position: relative;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  @include small-breakpoint {
    flex-basis: 100%;
  }
}

.featured-person__container--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.featured-person__content-boundry {
  width: 100%;
  max-width: calc(#{$max-content-width} / 2);
  padding: 40px;
  @include medium-breakpoint {
    padding: 20px;
  }
}

.featured-person__image {
  // width: 100%;
  // height: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  @include small-breakpoint {
    position: relative;
    top: auto;
    left: auto;
  height: auto;
    object-fit:contain;
  }
}

.featured-person__name {
  font-size: $font-size-med-3;
  line-height: $font-size-med-3-line;
  font-weight: 700;
  @include medium-breakpoint {
    font-size: $font-size-med-3;
  }
  @include small-breakpoint {
    font-size: $font-size-med;
    line-height: $font-size-med-line;
  }
}

.featured-person__title {
  font-family: $font-cdmsmith-sans;
  font-size: $font-size-small-3;
  line-height: $font-size-small-3-line;
  font-style: normal;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include small-breakpoint {
    font-size: $font-size-small-2;
    line-height: $font-size-small-2-line;
  }
}

.featured-person__bio {
  font-size: $font-size-small-3;
  line-height: $font-size-small-3-line;
  margin-top: 24px;
  @include small-breakpoint {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
  }
}

@media only screen and (max-width:799px) {
  .featured-person__name{
      font-size: $font-size-med;
      line-height: $font-size-med-line;
  }

  .featured-person__title{
      font-size: $font-size-small-2;
      line-height: $font-size-small-2-line;
  }
}

.featured-person__button {
  margin-top: 30px;
  @include small-breakpoint {
    margin-top: 20px;
    margin-bottom: -10px;
  }
}

.featured-person__button.btn.btn--blue
{
  color: $color-white !important;
  background-color: $color-brand-dark-blue !important;
  &:hover {
    color: $color-brand-dark-blue !important;
    background-color: $color-white !important;
    outline: 2px solid $color-brand-dark-blue !important;
  }
}


.featured-person__color-overlay {
  position: absolute;
  top: 0;
  transition: all 2s ease;
  height: 100%;
  /* size options */
  &.size-large {
    width: 50%;
    height: 100%;
  }

  &.size-medium {
    width: 30%;
    height: 100%;
  }

  &.size-small {
    width: 10%;
    height: 100%;
  }
  @include small-breakpoint {
    display: none;
  }
  /* color options */
  &.theme-blue {
    background-color: $color-transparent-blue;
  }

  &.theme-light-blue {
    background-color: $color-transparent-light-blue;
  }

  &.theme-green {
    background-color: $color-transparent-green;
  }

  &.theme-maroon {
    background-color: $color-transparent-maroon;
  }

  &.theme-orange {
    background-color: $color-transparent-orange;
  }

  &.theme-teal {
    background-color: $color-transparent-teal;
  }
}
/* Component alignment alternates left/right on desktop */
.featured-person.img-left {
  .featured-person__content-boundry {
    align-self: flex-start;
    padding-left: 80px;
  }

  .featured-person__color-overlay {
    right: 50px;

    &.animated.size-small {
      right: -20px;
    }

    &.animated.size-medium {
      right: -40px;
    }

    &.animated.size-large {
      right: -60px;
    }
  }
}

.featured-person.img-right {
  .featured-person__container--image {
    order: 2;
  }

  .featured-person__content-boundry {
    align-self: flex-end;
    padding-right: 80px;
  }


  .featured-person__color-overlay {
    left: 50px;

    &.animated.size-small {
      left: -20px;
    }

    &.animated.size-medium {
      left: -40px;
    }

    &.animated.size-large {
      left: -60px;
    }
  }
}
/* doesn't alternate on mobile */
.featured-person.img-left,
.featured-person.img-right {
  @include small-breakpoint {
    .featured-person__content-boundry {
      padding: 40px;
    }

    .featured-person__container--image {
      order: 1;
    }

    .featured-person__color-overlay {
      top: auto;
      right: 0;
      bottom: -10px;
      left: 0;
    }
  }
}
/* button at bottom of container */
.featured-people__button {
  text-align: center;
  position: relative;
  margin-top: 25px;
}

.featured-people__button .btn {
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    border: 2px solid $color-brand-light-blue-acc;
  }
}
