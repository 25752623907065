$teaser-padding: 10px;
$video-icon-size: 90px;
$mobile-video-icon-size: 50px;
$tablet-landscape-height: 768px;

.teaser-block-section__wrapper {
  .slick-arrow {
    top: 70%;

    @media (max-width: 500px) {
      top: 52%;
    }
  }
}
.teaser-block-section__slider {
  @extend %content-contained;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media screen and (max-height: $tablet-landscape-height) {
    max-height: 100vh;
  }
}

.teaser-block-section__button {
  font-family: $font-cdmsmith-light-sans !important;
  margin: 30px 0;
  text-align: center;

  @include small-breakpoint {
    margin: 30px 20px;
  }
}

.teaser-block-section__button .btn {
  color: $color-white;
  background-color: $color-brand-light-blue-acc;
  &:hover {
    color: $color-brand-light-blue-acc;
    background-color: $color-white;
    outline: 2px solid $color-brand-light-blue-acc;
  }
}

.teaser-block-section__button .btn--blue {
  color: $color-white !important;
  background-color: $color-brand-dark-blue !important;
  &:hover {
    color: $color-brand-dark-blue !important;
    background-color: $color-white !important;
    outline: 2px solid $color-brand-dark-blue !important;
  }
}

.teaser-block {
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 $teaser-padding;
}

.teaser-block__link:hover {
  border-bottom: 0;

  .teaser-block__headline span {
    @extend a:hover;
  }
}

.teaser-block__tag {
  font-size: $font-size-small;
  line-height: $font-size-small-line;
  position: absolute;
  top: 20px;
  right: $teaser-padding;
  z-index: 1;
  display: inline-block;
  padding: 0 10px;
  font-weight: 600;
  color: $color-white;
  text-transform: uppercase;
  background: $color-brand-dark-blue;
}

.teaser-block__media {
  @extend %flex-center;
  display: block;
  width: 100%;
  background-color: $color-gray-02;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-height: $tablet-landscape-height) {
    max-height: calc(100vh - 115px);
    width: auto;
  }
}

.teaser-block__media-picture {
  inset: 0;
  position: absolute;

  img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }
}

.teaser-block-section--2 .teaser-block__media {
  @include aspect-ratio(4, 3);

  @include small-breakpoint {
    @include aspect-ratio(1, 1);
  }
}

.teaser-block-section--3 .teaser-block__media {
  @include aspect-ratio(1, 1);
}

.teaser-block__video-icon {
  position: absolute;
  // background gray circle
  z-index: 0;
  display: block;
  width: $video-icon-size;
  height: $video-icon-size;
  background-color: $color-dim-50;
  border-radius: 50%;
  top: calc(50% - (#{$video-icon-size} / 2));
  left: calc(50% - (#{$video-icon-size} / 2));

  @include small-breakpoint {
    width: $mobile-video-icon-size;
    height: $mobile-video-icon-size;
    top: calc(50% - (#{$mobile-video-icon-size} / 2));
    left: calc(50% - (#{$mobile-video-icon-size} / 2));
  }

  .fa {
    z-index: 1;
    color: $color-white;
    position: relative;
    left: 28px;
    top: 28px;

    @include small-breakpoint {
      left: 13px;
      top: 13px;
      font-size: 24px;
    }
  }
}

.teaser-block__content-type {
  font-size: $font-size-small;
  line-height: $font-size-small-line;
  display: block;
  padding-top: 10px;
  font-weight: 600;
  color: $color-text-subtle;
  text-transform: uppercase;
}

.teaser-block__headline,
.teaser-block__name {
  display: block;
  padding-top: 10px;

  @media screen and (max-height: $tablet-landscape-height) {
    padding-top: 0;
  }

  span {
    margin-top: 34px !important;
    font-size: $font-size-med;
    line-height: $font-size-med-line;

    @extend h3;
    @include small-breakpoint {
      font-size: $font-size-small-3;
      line-height: $font-size-small-3-line;
    }
  }
}

.teaser-block__title {
  display: block;
  padding-top: 5px;

  span {
    font-style: italic;
  }
}

.teaser-block-section--3.slick-initialized {
  .slick-slide {
    padding: $teaser-padding;
  }

  .teaser-block__tag {
    right: $teaser-padding;
  }
}

.slick-initialized .slick-slide {
  // so we don't accidentally click to a detail page of the non-active slide
  .teaser-block__link {
    pointer-events: none; // didn't seem to work on its own. also using js
    cursor: default;
  }

  // override for current slide
  &.slick-current {
    .teaser-block__link {
      pointer-events: auto;
      cursor: pointer;
    }
  }
}
