.shell {
    position: relative;
    height: 41px;
    min-width: 300px;
    display: inline-block;

    span {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;

        i {
            font-style: normal;
            /* any of these 3 will work */
            color: transparent;
            opacity: 0;
            visibility: hidden;
        }
    }
}

input.masked,
.shell span {
    padding: 10px 15px;
    font-size: 16px;
    height: 41px;
    min-width: 300px;
    font-family: sans-serif;
    margin: 0px;
    display: inline;
    vertical-align: baseline;
    line-height: 18px;
    font-weight: 400;
    border: 1px solid $color-gray-15;
}
