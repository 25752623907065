.contrast-toggle {
    padding-right: 20px;
    padding-left:  20px;
    font-size: $font-size-small;

    @include small-breakpoint {
        padding: 10px 0;
    }

    .fa {
        transition: $standard-transition;
        padding-left: 0;

        @include small-breakpoint {
            color: $color-white;
            width: 12px;
        }
    }

    &--on .fa {
        transform: scaleX(-1);
        transform-origin: center;
    }
}

// header themes
.header--over-banner {
    .contrast-toggle--on .fa {
        color: $color-white;
    }
}

.header--without-banner {
    .contrast-toggle--on .fa {
        color: $color-brand-green;
    }
}
