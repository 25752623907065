/*------------------------------------*\
    #ACCESSIBILITY
\*------------------------------------*/


/* Complely remove from the flow but leave available to screen readers */
.screen-reader-only {
    @include visually-hidden;
    /*  adding colors even though it's not visible
        just to shut up contrast errors */
    background-color: $color-black;
    color: $color-white;
}

/* Skip link is hidden until focused */
.skip-link {
    @include medium-and-up();
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    width: 100%;
    max-width: $max-content-width;
    margin: 0 auto;

    &:focus {
        position: static !important; // gross, sorry, accessibility override
        width: auto;
        height: auto;
    }

    .header--over-banner & {
        color: $color-white !important; // gross, sorry, accessibility override
    }

    .header--without-banner & {
        color: $color-black !important; // gross, sorry, accessibility override
    }
}

.fa-external-link {
    color: $color-brand-light-blue;
    font-size: 10px;
}

/* from https://ghinda.net/article/mimic-native-focus-css/ */
*:focus {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* WebKit gets its native focus styles. */
    @media (-webkit-min-device-pixel-ratio:0) {
        outline: -webkit-focus-ring-color auto 5px;
    }
}
